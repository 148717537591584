/*
 * @description: ChordSettingAction
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-02 17:27:14
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-24 17:00:13
 */

import { InputMode } from "@/enum/input-mode"
import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import DataCheck from "@/utils/data-check"
import { State } from "vue"

export default class ChordAction {
  public name = 'ChordSetting'

  beforeExecute(props: any) {
    const { $store, $message } = props
    const state = $store.state as State
    if (state.inputMode === InputMode.Add) {
      if (!state.isRenderTune) {
        $message.info('请先渲染出一个曲谱出来')
        return
      }
      if (state.stateMode === StateMode.Ties) {
        $store.commit('changeStateMode', StateMode.None)
      }
      if (state.selectedNotes.length <= 0) {
        $message.info('请选中一个音符')
        return
      }

      const selectedNote = state.selectedNotes[0]
      const { el_type } = selectedNote

      if (el_type !== 'note') {
        $message.info('请选中一个音符')
        return
      }
      $store.commit('changeStateMode', StateMode.ChordSetting)
    } else {
      $message.warning('和弦只能在新增的时候插入');
    }
  }

  execute(props: any, args: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    if (state.inputMode === InputMode.Add) {
      const selectedNote = state.selectedNotes[0]
      const { startChar, endChar, type } = selectedNote
      const abcString = state.abcString
      const tuneInfo = state.tuneInfo
      const value = args
      const abcStringHelper = new AbcStringHelper(tuneInfo)
      let inputMode: InputMode
      const { selectables } = $abcJsHelper as AbcJsHelper

      if (type === 'rest') {

        //过滤同一小节的所有终止符元素
        const sameMeasures = selectables.filter(s => {
          return s.v === selectedNote.v && s.mm === selectedNote.mm && s.type == type
        }) || []
        //若是小节仅只有一个终止符，并且为选中的音符，模式默认变更为修改模式，替换该终止符
        if (sameMeasures.length === 1 && sameMeasures[0].startChar === startChar && sameMeasures[0].endChar === endChar) {
          inputMode = InputMode.Edit
        } else {
          inputMode = state.inputMode
        }
      } else {
        inputMode = state.inputMode
      }
      const result = abcStringHelper.insertOrUpdateNote(startChar, endChar, abcString, `${value} `, inputMode, selectables, selectedNote)
      if (DataCheck.$isString(result)) {
        $message.info(result)
        return
      }
      const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result as any
      $store.commit('changeAbcString', newAbcStr)
      $store.commit('clearSelectedNotes')
      if (newStartChar !== 0 && newEndChar !== 0) {
        $store.commit('changeSelectedNotes', {
          startChar: newStartChar,
          endChar: newEndChar
        })
      }

    } else {
      $message.warning('和弦只能在新增的时候插入')
    }
  }
}