
import _ from 'lodash'
import abcjs from 'abcjs'
import {
	defineComponent,
	ref,
	reactive,
	watch,
	toRaw,
	nextTick,
	computed
} from 'vue'
import DoubleStateSelect from '../common/double-state-select.vue'
export default defineComponent({
	name: 'chord-setting',
	components: { DoubleStateSelect },
	props: {
		unitNoteLength: {
			type: String,
			default: '1/4'
		},
		major: {
			type: String,
			default: 'D'
		},
		clef: {
			type: String,
			default: 'treble'
		},
		selectNote: String
	},
	setup(props, ctx) {
		//时值数据
		const timeValueArray = [
			{
				value: 32,
				img: '/resource/img/note/32分音符.png'
			},
			{
				value: 16,
				img: '/resource/img/note/16分音符.png'
			},
			{
				value: 8,
				img: '/resource/img/note/8分音符.png'
			},
			{
				value: 4,
				img: '/resource/img/note/4分音符.png'
			},
			{
				value: 2,
				img: '/resource/img/note/2分音符.png'
			},
			{
				value: 1,
				img: '/resource/img/note/全音符.png'
			}
		]
		// 时值
		const timeValue = ref<number | null>(null)

		const ul = Number.parseInt(props.unitNoteLength.split('/')[1])
		timeValue.value = ul

		// 原始赋值
		const _ptich: { [key: string]: any } = {
			C: null, // 高音谱表
			D: null,
			E: null,
			F: null,
			G: null,
			A: null,
			B: null,
			'C,': null, // 低音谱表
			'D,': null,
			'E,': null,
			'F,': null,
			'G,': null,
			'A,': null,
			'B,': null
		}
		// 时值点击
		const onTimeValueClick = (value: any) => {
			timeValue.value = value
			const _pitchArray = getPitchArray()
			Object.assign(pitchArray, _pitchArray)
			Object.assign(pitch, _ptich)
		}
		let pitch = reactive(_.cloneDeep(_ptich))
		let treblePitchLetters = ['C', 'D', 'E', 'F', 'G', 'A', 'B']
		let bassPitchLetters = ['C,', 'D,', 'E,', 'F,', 'G,', 'A,', 'B,']
		const pitchLetters = computed<Array<string>>(() => {
			if (props.clef === 'treble') {
				return treblePitchLetters
			} else {
				return bassPitchLetters
			}
		})

		// 获取几分音符对应的字母
		const getRealLetter = (letter: string) => {
			const _timeValue = timeValue.value as number
			const _ul = ul
			const res = _timeValue / _ul
			const isDivision = res > 1
			const fraction = isDivision
				? parseInt(`${res}`)
				: parseInt(`${_ul / _timeValue}`)
			return isDivision
				? `${letter}/${fraction}`
				: `${letter}${fraction === 1 ? '' : fraction}`
		}

		// 获取下拉数据
		const getPitchArray = () => {
			return pitchLetters.value.map((letter: string) => {
				const value = getRealLetter(letter)
				return {
					letter,
					display: `/resource/img/chord/${letter}.png`,
					data: [
						{
							value,
							icon1: `/resource/img/chord/${letter}.png`
						},
						{
							value: `^${value}`,
							icon1: `/resource/img/chord/${letter}.png`,
							icon2: '/resource/img/chord/上升.png'
						},
						{
							value: `_${value}`,
							icon1: `/resource/img/chord/${letter}.png`,
							icon2: '/resource/img/chord/下降.png'
						},
						{
							value: `=${value}`,
							icon1: `/resource/img/chord/${letter}.png`,
							icon2: '/resource/img/chord/还原.png'
						}
					]
				}
			})
		}
		let pitchArray = getPitchArray()
		pitchArray = reactive(pitchArray)

		// 监听时值，从而改变pitch的值
		watch(timeValue, (newVal) => {
			console.log(newVal)
		})

		// 获取和弦字符串
		const getChordValue = (pitch: any) => {
			const pitchs = Object.values(toRaw(pitch) || []).filter(Boolean)
			return `[${_.join(pitchs, ' ')}]`
		}

		//渲染预览效果
		const renderPreview = (chordString: string) => {
			const abcString =
				'X:1\n' +
				`L:${props.unitNoteLength}\n` +
				`V:1 clef=${props.clef}\n` +
				`K:${props.major}\n` +
				`[V:1] ${chordString} |`

			nextTick(() => {
				abcjs.renderAbc('preview-chord', abcString, {
					scale: 1.5
				})
			})
		}

		// 初次预览
		renderPreview(getChordValue(pitch))
		// 监听和弦 渲染数据
		watch(
			() => _.cloneDeep(pitch),
			(newVal) => {
				newVal = newVal || {}
				const chordString = getChordValue(newVal)
				renderPreview(chordString)
			}
		)

		//确定取消
		const onBtnClick = (isConfirm: boolean) => {
			if (isConfirm) {
				const chordString = getChordValue(pitch)
				ctx.emit('chordStringChange', chordString)
			}
			ctx.emit('close')
		}
		return {
			timeValueArray,
			timeValue,
			onTimeValueClick,
			pitchArray,
			pitch,
			onBtnClick
		}
	}
})
