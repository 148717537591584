export default class Beams {
  curPos: number;
  retArr: any[];
  curBeat: number;
  prePos: number;
  constructor() {
    this.curPos = 0;//当前位置
    this.retArr = [];//返回的连接数组
    this.curBeat = 0;//当前的音符节拍取余
    this.prePos = 0;
  }

  setBeamEx(pre: number, cur: number) {
    if ((cur - pre) >= 1) {
      this.retArr.push([pre, cur])
    }
  }

  setBeam(isCur: boolean) {
    if (isCur == true) {
      this.setBeamEx(this.prePos, this.curPos)
    } else if (isCur == false) {
      this.setBeamEx(this.prePos, this.curPos - 1)
    }
    this.prePos = this.curPos + 1;
  }

  calculateBeat(note: number, beat: number, unit: number) {
    const noteBeat = 4 * note * (4 / unit);
    if (noteBeat >= beat) {
      this.setBeam(false)
      this.curBeat = 0;
      return
    }
    this.curBeat += noteBeat;
    if (this.curBeat >= beat) {
      this.setBeam(true)
      this.curBeat -= beat;
    }
  }

  timeModification(noteArr: string | any[], beat: number, unit: number) {
    let actual = 0;
    if (noteArr.length < 4) {
      actual = 2;
    } else if (noteArr.length < 8) {
      actual = 4;
    } else if (noteArr.length < 16) {
      actual = 8;
    } else if (noteArr.length < 32) {
      actual = 16;
    }

    for (let i = 0;i < noteArr.length;i++) {
      this.calculateBeat((noteArr[i] * actual) / noteArr.length, beat, unit)
      this.curPos++;
    }
  }

  generateBeams(noteArr: string | any[], beat: number, unit: number) {
    this.curPos = 0;
    this.prePos = 0;
    this.curBeat = 0;
    this.retArr = new Array<any>();

    for (let i = 0;i < noteArr.length;i++) {
      if (Array.isArray(noteArr[i])) {
        this.timeModification(noteArr[i], beat, unit)
      } else {
        this.calculateBeat(noteArr[i], beat, unit)
        this.curPos++;
      }
    }

    return this.retArr
  }

  calculateBeams(time: string | any[], unit: any, noteArr: string | any[]) {
    if (!Array.isArray(time)
      || (time.length != 2)
      || !Number.isInteger(unit)
      || !Array.isArray(noteArr)) {
      return;
    }

    let beat = 0;

    if (time[1] == 2) {
      beat = 8
    } else if (time[1] == 4) {
      if ((time[0] % 2) == 1) {
        beat = 4 * time[0]
      } else {
        beat = 4 * 2
      }
    } else if (time[1] == 8) {
      if ((time[0] % 3) == 0) {
        beat = 6
      } else {
        beat = 2
      }
    }

    if (beat == 0) {
      return
    }

    return this.generateBeams(noteArr, beat, unit)
  }

}