
import { $loadConfig } from '@/utils/load-config'
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'tool-bar',
	async setup() {
		const toolData = await $loadConfig('/config/module-config/tool-bar.json')
		return { toolData }
	},
	methods: {
		/**
		 * 执行动作
		 */
		execAction(data: any, position?: string) {
			const { action, options = {}, type } = data
			if (type === 'button') {
				this.$command.execAction(action, options)
			} else if (type === 'switch') {
				const option = options[position as string]
				this.$command.execAction(action, option)
			}
		}
	}
})
