
import useCurrentInstance from '@/hook/useCurrentInstance'
import _ from 'lodash'
import { defineComponent, reactive, ref, toRaw, watch } from 'vue'
export default defineComponent({
	name: 'tune-info-setting',
	props: {
		tuneInfo: {
			requird: true,
			type: Object
		}
	},
	setup(props, ctx) {
		const instance = useCurrentInstance() as any
		const formRef = ref()
		//表单modal
		const formModal = reactive(
			Object.assign(
				{},
				{
					title: '',
					major: '',
					timeSignature: '',
					author: '',
					description: ''
				},
				props.tuneInfo || {}
			)
		)
		const rules = {
			title: [
				{
					required: true,
					message: '请输入标题',
					trigger: 'blur'
				}
			],
			major: [
				{
					required: true,
					message: '请选择大调',
					trigger: 'blur'
				}
			],
			timeSignature: [
				{
					required: true,
					message: '请选择拍号',
					trigger: 'blur'
				}
			]
		}
		const _timeSignature = _.cloneDeep(formModal.timeSignature)
		let isChangeTimeSignture = false
		watch(
			() => formModal.timeSignature,
			(newVal, oldVal) => {
				if (newVal !== _timeSignature) {
					instance?.$message?.warn('更改拍号，会重置整个曲谱')
					isChangeTimeSignture = true
				} else {
					instance?.$message?.warn('拍号已还原')
					isChangeTimeSignture = false
				}
			}
		)
		const onBtnClick = (isConfirm: boolean) => {
			if (isConfirm) {
				formRef.value.validate().then(() => {
					ctx.emit('tuneInfoChange', toRaw(formModal), isChangeTimeSignture)
				})
			} else {
				ctx.emit('close')
			}
		}
		return {
			formRef,
			formModal,
			rules,
			onBtnClick,
			labelCol: { span: 6 },
			wrapperCol: { span: 14 }
		}
	}
})
