/*
 * @description: AbcStringHelper
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-04 10:13:37
 * @lastEditors: xuhuale
 * @lastEditTime: 2022-04-25 17:34:55
 */

import { InputMode } from '@/enum/input-mode'
import { TuneBookMode } from '@/enum/tune-book-mode'
import _ from 'lodash'
import DataCheck from '../data-check'
import decimal2fraction from '../decimal2fraction'
import Beams from './generateBeams'

export default class AbcStringHelper {
	public readonly tuneInfo: any

	public readonly tuneInfoLetterMap = [
		{ reg: /T:.*/, key: 'title' },
		{ reg: /M:.*/, key: 'timeSignature' },
		{ reg: /L:.*/, key: 'UnitLen' },
		{ reg: /Q:.*/, key: 'Speed' },
		{ reg: /R:.*/, key: 'description' },
		{ reg: /C:.*/, key: 'author' },
		{ reg: /K:.*/, key: 'major' }
	]

	public readonly allPitches = [
		'C,,,,',
		'D,,,,',
		'E,,,,',
		'F,,,,',
		'G,,,,',
		'A,,,,',
		'B,,,,',
		'C,,,',
		'D,,,',
		'E,,,',
		'F,,,',
		'G,,,',
		'A,,,',
		'B,,,',
		'C,,',
		'D,,',
		'E,,',
		'F,,',
		'G,,',
		'A,,',
		'B,,',
		'C,',
		'D,',
		'E,',
		'F,',
		'G,',
		'A,',
		'B,',
		'C',
		'D',
		'E',
		'F',
		'G',
		'A',
		'B',
		'c',
		'd',
		'e',
		'f',
		'g',
		'a',
		'b',
		"c'",
		"d'",
		"e'",
		"f'",
		"g'",
		"a'",
		"b'",
		"c''",
		"d''",
		"e''",
		"f''",
		"g''",
		"a''",
		"b''",
		"c'''",
		"d'''",
		"e'''",
		"f'''",
		"g'''",
		"a'''",
		"b'''",
		"c''''",
		"d''''",
		"e''''",
		"f''''",
		"g''''",
		"a''''",
		"b''''",
		'z'
	]

	public readonly dotsDurationTables = [
		{
			duration: 1,
			durations: [1, 1.5, 1.75, 1.875]
		},
		{
			duration: 0.5,
			durations: [0.5, 0.75, 0.875, 0.9375]
		},
		{
			duration: 0.25,
			durations: [0.25, 0.375, 0.4375, 0.46875]
		},
		{
			duration: 0.125,
			durations: [0.125, 0.1875, 0.21875, 0.234375]
		},
		{
			duration: 0.0625,
			durations: [0.0625, 0.09375, 0.109375, 0.1171875]
		},
		{
			duration: 0.03125,
			durations: [0.03125, 0.046875, 0.0546875, 0.05859375]
		}
	]

	constructor(tuneInfo: any) {
		this.tuneInfo = tuneInfo
	}

	/**
	 * 初始化一个单谱表
	 * @returns
	 */
	public newSingleAbcString(): string {
		const { tuneInfo } = this
		const restNote = `z${tuneInfo.timeSignature.split('/')[0]}`
		//     return `X: 1
		// T:标题
		// M:4/4
		// L:1/4
		// Q:1/4=100
		// R:欢快的
		// C:作者
		// K:C
		// [V:1]C/4C/4C/4C/4C/4C/4C/4C/4 C/4C/4C/4C/4C/4C/4C/4C/4 |C/4C/4C/4C/4C/4C/4C/4C/4 C/4 C/4 C/4 C/4 C/4 C/4 z1/2 |z4 |z4 |z4 |z4 |z4 |z4 |z4 |z4 |z4 |z4 |z4 |]
		// ` as string
		return `X: 1
T:${tuneInfo.title}
M:${tuneInfo.timeSignature}
L:1/${tuneInfo.timeSignature.split('/')[1]}
Q:1/${tuneInfo.timeSignature.split('/')[1]}=100
R:${tuneInfo.description || ''}
C:${tuneInfo.author}
K:${tuneInfo.major}
[V:1]${restNote} |${restNote} |${restNote} |${restNote} |]
`
		// [V:1]C z1/1 C z1/1 |C/8C/8C/8C/8C/4C/4 C/4C/4C/4C/4 C/2 C z1/2 |C/2C/2 C/2C/2 C C |C/2C/2 C/2C/2 C/2C/2 C/2C/2 |C/2C/2 C/2C/2 C/2C/2 C/2C/2 |z4 |C/8C/8C/8C/8C/8C/8C/8C/8 C/8C/8C/4C/4C/4 C/4 z1/4 z1/2 z1/1 |C/4C/4C/4C/4 C/4C/4C/4C/2 C/2C/2 z1/4 z1/2 |C/4C/4C/4C/4 C/4C/4C/4C/4 C/4C/4C/4C/4 C/4C/4C/4C/4 |C/4C/4C/4C/4 C/4C/4C/4C/4 C/4C/4C/4C/4 C/4C/4C/4C/4 |z4 |z4 |
		// V:2 clef=bass
		// [V:2]C C z2/1 |z4 |z4 |z4 |C/4C/4C/4C/4 C/4C/4C/4C/4 C/4C/4C/4C/4 z1/1 |z4 |C/4C/4C/4C/4 C/4C/4C/4C/4 C/4C/4C/4C/4 z1/1 |C/8C/8C/8C/8C/8C/8C/8C/8 C/8C/8C/8C/8C/8C/8C/8C/8 C/8C/8C/8C/8C/8C/8C/8C/8 C/4C/4C/4C/4 |C/4C/4C/8C/8C/8C/8 C/4 z1/4 z1/2 z2/1 |C/2C/2 C/2C/2 z2/1 |z4 |z4 |
		// `
		//[V:1]${restNote} |${restNote} |${restNote} |${restNote} |]

		//(C A B) C/4 [C A B] C
	}

	/**
	 * 初始化一个高低音谱表
	 * @returns
	 */
	public newDoucleAbcString(): string {
		const { tuneInfo } = this
		const restNote = `z${tuneInfo.timeSignature.split('/')[0]}`
		return `X: 1
T:${tuneInfo.title}
M:${tuneInfo.timeSignature}
L:1/${tuneInfo.timeSignature.split('/')[1]}
Q:1/${tuneInfo.timeSignature.split('/')[1]}=100
R:${tuneInfo.description || ''}
C:${tuneInfo.author}
K:${tuneInfo.major}
%%staves {1 2}
V:1 clef=treble
[V:1]${restNote} |${restNote} |${restNote} |${restNote} |]
V:2 clef=bass
[V:2]${restNote} |${restNote} |${restNote} |${restNote} |]
`
	}

	/**
	 * 更新头部信息到字符串
	 * @param abcStr
	 */
	public updateTuneInfo(abcStr: string) {
		//改变拍数，要替换全部的休止符，比如1/4=>z4变成1/8 就要变成z8
		// const arr = /L:.*/.exec(abcStr)
		// if (arr) {
		//   const str = arr[0]
		//   const values = str.split(':')
		//   const unl = values[1].split('/')[1]
		//   const replaceStr = `/z${unl}/`
		//   abcStr = abcStr.replace(replaceStr, `z${this.tuneInfo.timeSignature.split('/')[1]}`)
		// }
		this.tuneInfoLetterMap.forEach(({ reg, key }) => {
			const array = reg.exec(abcStr)
			if (array) {
				const str = array[0]
				const index = array.index
				const endChar = index + str.length
				const values = str.split(':')
				let replaceStr = ''
				if (DataCheck.$isFunction((this as any)[`replace${key}`])) {
					// eslint-disable-next-line
					replaceStr = (this as any)[`replace${key}`](str, abcStr)
				} else {
					replaceStr = this.tuneInfo[key as string]
				}
				replaceStr = [values[0], replaceStr].join(':')
				abcStr = `${abcStr.substring(0, index)}${replaceStr}${abcStr.substring(
					endChar
				)}`
			}
		})

		return abcStr
	}

	/**
	 * 将字符串的头部信心转换成对象
	 * @param abcStr
	 */
	public convertToTuneInfo(abcStr: string) {
		this.tuneInfoLetterMap.forEach(({ reg, key }) => {
			const array = reg.exec(abcStr)
			if (array) {
				const str = array[0]
				const values = str.split(':')
				if (DataCheck.$isFunction((this as any)[`convertTo${key}`])) {
					(this as any)[`convertTo${key}`](values[1])
				} else {
					this.tuneInfo[key] = values[1]
				}
			}
		})
	}

	/**
	 * 根据对应的abc信息，返回一个对应时值的Cnote
	 * @param value
	 */
	public changeNoteTimeValue(tletter: string, timeValue: number) {
		const tuneInfo = this.tuneInfo
		const ul = tuneInfo.timeSignature.split('/')[1]
		const pitch = this.getCharPtich(tletter)
		const note = pitch.find((p) => p.isNote)
		if (note) {
			tletter = note.str
		}
		return this.getRealLetter(tletter, timeValue, ul)
	}

	/**
	 * 新增，或删除小节
	 * @param selectedNote
	 * @param abcString
	 * @param selectables
	 * @param type
	 */
	public insertOrUpdateMeasure(
		selectedNote: any,
		abcString: string,
		selectables: any[],
		type: any,
		tuneBookMode: TuneBookMode
	) {
		let newAbcStr = ''
		const v = selectedNote.v
		const mm = selectedNote.mm
		const sameMeasures =
			selectables.filter((s) => {
				return s.v === selectedNote.v && s.mm === selectedNote.mm
			}) || []
		const startChar = _.minBy(sameMeasures, (item) => {
			return item.startChar
		})?.startChar
		const endChar = _.maxBy(sameMeasures, (item) => {
			return item.endChar
		})?.endChar

		const nextv = v == '0' ? '1' : '0'
		const nextSameMeasures =
			selectables.filter((s) => {
				return s.v === nextv && s.mm === mm
			}) || []

		const nextStartChar = _.minBy(nextSameMeasures, (item) => {
			return item.startChar
		})?.startChar
		const nextEndChar = _.maxBy(nextSameMeasures, (item) => {
			return item.endChar
		})?.endChar
		let newStartChar = 0,
			newEndChar = 0
		if (type === 1) {
			//新增
			const newStr = `z${this.tuneInfo.timeSignature.split('/')[0]} |`
			const newStrLen = newStr.length
			switch (tuneBookMode) {
				case TuneBookMode.Single:
					{
						const prefixStr = abcString.substring(0, startChar)
						const suffixStr = abcString.substring(endChar, abcString.length)
						const sourceStr = abcString.substring(startChar, endChar)
						newAbcStr = `${prefixStr}${sourceStr}${newStr}${suffixStr}`
						newStartChar = endChar + newStrLen - 1
						newEndChar = endChar + newStrLen
					}
					break
				case TuneBookMode.Double:
					{
						const [, char2, , char4] = _.orderBy([
							startChar,
							endChar,
							nextStartChar,
							nextEndChar
						])
						const char1Str = abcString.substring(0, char2)
						const char2Str = abcString.substring(char2, char4)
						const char3Str = abcString.substring(char4, abcString.length)
						newAbcStr = `${char1Str}${newStr}${char2Str}${newStr}${char3Str}`
						newStartChar = char2 + newStrLen - 1
						newEndChar = char2 + newStrLen
					}
					break
				default:
					break
			}
		} else {
			//删除
			switch (tuneBookMode) {
				case TuneBookMode.Single:
					{
						const prefixStr = abcString.substring(0, startChar)
						const suffixStr = abcString.substring(endChar, abcString.length)
						newAbcStr = `${prefixStr}${suffixStr}`
					}
					break
				case TuneBookMode.Double:
					{
						const [char1, char2, char3, char4] = _.orderBy([
							startChar,
							endChar,
							nextStartChar,
							nextEndChar
						])
						const char1Str = abcString.substring(0, char1)
						const char2Str = abcString.substring(char2, char3)
						const char3Str = abcString.substring(char4, abcString.length)
						newAbcStr = `${char1Str}${char2Str}${char3Str}`
					}
					break
				default:
					break
			}
		}

		return {
			newAbcStr,
			startChar: newStartChar,
			endChar: newEndChar
		}
	}

	/***
	 * 修改临时记号
	 */
	public changeAccidentals(sourceStr: string, value: any, mutex: any) {
		mutex.forEach((letter: string) => {
			sourceStr = sourceStr.replace(letter, '')
		})

		//因为可能会有连音 连音会将“(”带入
		let arr = this.tokenize(sourceStr)
		arr = arr.map((item) => {
			const x = this.allPitches.indexOf(item)
			if (x > -1) {
				return `${value}${item}`
			} else {
				return item
			}
		})
		return arr.join('')
	}

	/**
	 * 根据模式重新组织字符串
	 * @param startChar
	 * @param endChar
	 * @param abcString
	 * @param note
	 * @param inputMode
	 * @returns
	 */
	public insertOrUpdateNote(
		startChar: any,
		endChar: any,
		abcString: string,
		note: string,
		inputMode: InputMode,
		selectables?: any[],
		selectedNote?: any
	) {
		const prefixStr = abcString.substring(0, startChar)
		const suffixStr = abcString.substring(endChar, abcString.length)
		const sourceStr = abcString.substring(startChar, endChar)
		const newStr = note
		const newStrLen = newStr.length
		let newAbcStr = ''
		let newSelectedNote: any = {}
		const errorMsg = '该小节总时值已满，请修改音符或选择下一小节'
		if (inputMode === InputMode.Add) {
			newAbcStr = `${prefixStr}${sourceStr}${newStr}${suffixStr}`
			if (selectedNote.type === 'bar') {
				return {
					newAbcStr,
					startChar: endChar,
					endChar: endChar + newStrLen
				}
			}
			newSelectedNote = {
				startChar: endChar,
				endChar: endChar + newStrLen
			}
		} else if (inputMode === InputMode.Edit) {
			newAbcStr = `${prefixStr}${newStr}${suffixStr}`
			if (selectedNote.type === 'bar') {
				return {
					newAbcStr,
					startChar: startChar,
					endChar: startChar + newStrLen
				}
			}
			newSelectedNote = {
				startChar: startChar,
				endChar: startChar + newStrLen
			}
		} else {
			const sameMeasures =
				selectables?.filter((s) => {
					return (
						s.v === selectedNote?.v &&
						s.mm === selectedNote?.mm &&
						s.el_type == 'note'
					)
				}) || []
			const sameMeasuresLen = sameMeasures.length
			const rest = `z${this.tuneInfo.timeSignature.split('/')[0]} `
			let newStartChar = 0,
				newEndChar = 0
			//小节内容只有一个音符，怎么删都是终止符
			if (sameMeasuresLen === 1) {
				newAbcStr = `${prefixStr}${rest}${suffixStr}`
				newStartChar = startChar
				newEndChar = startChar + rest.length
			} else {
				//如果是删除该音符，小节全部都休止符的话，应该全部变成休止符
				const allNoRest = sameMeasures.filter((s) => s.type !== 'rest') || []
				const isAllRest = allNoRest.length <= 0 // 删除的音符所在全部小节都是休止符
				const isLastAllRest =
					(
						allNoRest.filter(
							(s) =>
								s.startChar !== selectedNote.startChar &&
								s.endChar !== selectedNote.endChar
						) || []
					).length <= 0 //删除的音符所在小节，除了选中的不是，全部都是休止符
				if (isAllRest || isLastAllRest) {
					const minStarChar =
						_.minBy(sameMeasures, (p) => p.startChar)?.startChar || 0
					const maxEndChar =
						_.maxBy(sameMeasures, (p) => p.endChar)?.endChar || 0
					newAbcStr = `${abcString.substring(
						0,
						minStarChar
					)}${rest}${abcString.substring(maxEndChar, abcString.length)}`
					return {
						newAbcStr,
						startChar: selectedNote.startChar,
						endChar: selectedNote.startChar + rest.length
					}
				}

				//正常删除流程
				newAbcStr = `${prefixStr}${suffixStr}`
				const selectedNoteIndex = selectables?.findIndex(
					(s) =>
						s.startChar === selectedNote.startChar &&
						s.endChar === selectedNote.endChar
				) as number
				const preNote = (selectables as any[])[selectedNoteIndex - 1]
				const nextNote = (selectables as any[])[selectedNoteIndex + 1]

				if (selectedNoteIndex > 0) {
					// 若是选中的不是整个音符的最后一个
					if (preNote.type === 'bar') {
						newStartChar = selectedNote.startChar
						newEndChar =
							selectedNote.startChar + nextNote.endChar - nextNote.startChar
					} else {
						newStartChar = preNote.startChar
						newEndChar = preNote.endChar
					}
				} else {
					// 删除的是音符的第一个
					newStartChar = selectedNote.startChar
					newEndChar =
						selectedNote.startChar + nextNote.endChar - nextNote.startChar
				}
			}

			if (selectedNote.type === 'bar') {
				return {
					newAbcStr,
					startChar: newStartChar,
					endChar: newEndChar
				}
			}
			newSelectedNote = {
				startChar: newStartChar,
				endChar: newEndChar
			}
		}
		// 改变了时值，需要重新计算时值，以及横梁的计算,
		//selectables需要深拷贝下再传入，以为下面的函数调用可能会修改，但是时值不对，会返回出去
		const selectables_bak = _.cloneDeep(selectables)
		const newSelectables = this.getNewSelectables(
			selectedNote,
			abcString,
			newSelectedNote,
			newAbcStr,
			selectables_bak as any[],
			inputMode
		)
		const { error, selectables: _selectables, newAbcStr: _newAbcStr } =
			this.checkMeasureDuration(newSelectables, newSelectedNote, newAbcStr) ||
			{}
		if (error) {
			return errorMsg
		}
		const res = this.calculateMovePosition(
			_selectables,
			newSelectedNote,
			_newAbcStr
		)
		return res
	}

	/**
	 * 将2个音符连音
	 * @param selectedNote1
	 * @param selectedNote2
	 * @param abcString
	 */
	public changeTiles(
		selectedNote1: any,
		selectedNote2: any,
		abcString: string
	) {
		let newAbcStr = ''
		const { startChar, endChar } = selectedNote1
		const { startChar: nextStartChar, endChar: nextEndChar } = selectedNote2
		const [char1, , , char4] = _.orderBy([
			startChar,
			endChar,
			nextStartChar,
			nextEndChar
		])
		const prefixStr = abcString.substring(0, char1)
		const suffixStr = abcString.substring(char4, abcString.length)
		let sourceStr = abcString.substring(char1, char4)
		if (sourceStr.substring(sourceStr.length - 1, sourceStr.length) === ' ') {
			sourceStr = sourceStr.substring(0, sourceStr.length - 1)
		}
		const tilesStar = '('
		const tilesEnd = ')'
		newAbcStr = `${prefixStr}${tilesStar}${sourceStr}${tilesEnd}${suffixStr}`
		return {
			newAbcStr,
			startChar: 0,
			endChar: 0
		}
	}

	/**
	 * 更改一个音符的音阶（高）
	 * @param startChar
	 * @param endChar
	 * @param abcString
	 * @param setp
	 * @returns
	 */
	public changeNotePitch(
		startChar: any,
		endChar: any,
		abcString: string,
		setp: any
	) {
		let newAbcStr = ''
		const prefixStr = abcString.substring(0, startChar)
		const suffixStr = abcString.substring(endChar, abcString.length)
		const sourceStr = abcString.substring(startChar, endChar)
		let newStr = ''
		let arr = this.tokenize(sourceStr) || []
		arr = arr.map((item) => {
			return this.moveNote(item, setp)
		})
		newStr = arr.join('')
		const newStrLen = newStr.length

		newAbcStr = `${prefixStr}${newStr}${suffixStr}`
		return {
			newAbcStr,
			startChar: startChar,
			endChar: startChar + newStrLen
		}
	}
	/**
	 * 移动音符音阶
	 * @param note
	 * @param step
	 * @returns
	 */
	public moveNote(note: string, step: number) {
		const x = this.allPitches.indexOf(note)
		const minIndex = this.allPitches.indexOf('A,,,,')
		const maxIndex = this.allPitches.indexOf("c'''")
		const newIndex = this.allPitches.indexOf(this.allPitches[x - step])
		if (
			x >= 0 &&
			this.allPitches[x - step] !== 'z' &&
			minIndex <= newIndex &&
			newIndex <= maxIndex
		) {
			return this.allPitches[x - step] ? this.allPitches[x - step] : note
		}

		return note
	}

	/**
	 * 解析一个音符字符串
	 * @param str
	 * @returns
	 */
	public tokenize(str: string) {
		const arr = str.split(/(!.+?!|".+?")/)
		let output: any[] = []
		for (let i = 0; i < arr.length; i++) {
			const token = arr[i]
			if (token.length > 0) {
				if (token[0] !== '"' && token[0] !== '!') {
					const arr2 = arr[i].split(/([A-Ga-g|z][,']*)/)
					output = output.concat(arr2)
				} else output.push(token)
			}
		}
		return output
	}

	/**
	 * 新增或删除附点
	 * @param startChar
	 * @param endChar
	 * @param abcString
	 * @returns
	 */
	public insertOrUpdateDots(
		startChar: any,
		endChar: any,
		abcString: string,
		selectables: any[],
		selectedNote: any
	) {
		let newAbcStr = ''
		const prefixStr = abcString.substring(0, startChar)
		const suffixStr = abcString.substring(endChar, abcString.length)
		const sourceStr = abcString.substring(startChar, endChar)
		let arr = this.getCharPtich(sourceStr) || []
		arr = arr.map((item) => {
			if (!item.isPtich) {
				return item.str
			}
			const matchStr = item.pitch
			const { index: matchStrIndex = 0 } = item.matchInfo || {}
			const value = this.getNewDotsPitch(selectedNote.abcelem.duration)
			const mprefixStr = item.str.substring(0, matchStrIndex)
			const msuffixStr = item.str.substring(
				matchStrIndex + matchStr.length,
				item.length
			)
			return `${mprefixStr}${value}${msuffixStr}`
		})
		const newStr = arr.join('')
		const newStrLen = newStr.length
		newAbcStr = `${prefixStr}${newStr}${suffixStr}`
		const newSelectedNote = {
			startChar,
			endChar: startChar + newStrLen
		}
		// return {
		//   newAbcStr,
		//   startChar,
		//   endChar: startChar + newStrLen
		// }
		const errorMsg = '该小节总时值已满，请修改音符或选择下一小节'
		const selectables_bak = _.cloneDeep(selectables)
		const newSelectables = this.getNewSelectables(
			selectedNote,
			abcString,
			newSelectedNote,
			newAbcStr,
			selectables_bak as any[],
			InputMode.Edit
		)
		const { error, selectables: _selectables, newAbcStr: _newAbcStr } =
			this.checkMeasureDuration(newSelectables, newSelectedNote, newAbcStr) ||
			{}
		if (error) {
			return errorMsg
		}
		const res = this.calculateMovePosition(
			_selectables,
			newSelectedNote,
			_newAbcStr
		)
		return res
	}

	/**
	 * 解析一小节，并将小节信息整理，带入beam计算，重新得到新的字符串，并得到新的选中的开始，结束位置
	 * @param selectedNote
	 * @param abcString
	 * @param newSelectedNote
	 * @param newAbcStr
	 * @param selectables
	 */
	public getNewSelectables(
		selectedNote: any,
		abcString: string,
		newSelectedNote: any,
		newAbcStr: string,
		selectables: any[],
		inputMode: InputMode
	) {
		// 获取源小节信息
		const sameMeasures =
			selectables.filter((s) => {
				return (
					s.v === selectedNote.v &&
					s.mm === selectedNote.mm &&
					s.el_type === 'note'
				)
			}) || []
		const selectedNoteIndex = _.findIndex(sameMeasures, (s) => {
			return (
				s.startChar == selectedNote.startChar &&
				s.endChar == selectedNote.endChar
			)
		})
		let measures: any[] = []
		const prefixMeasures = sameMeasures.slice(0, selectedNoteIndex)
		let suffixMeasures = sameMeasures.slice(
			selectedNoteIndex + 1,
			sameMeasures.length
		)
		let sourceMeasures = sameMeasures.slice(
			selectedNoteIndex,
			selectedNoteIndex + 1
		)
		let interval = 0
		if (inputMode == InputMode.Add) {
			interval = newSelectedNote.endChar - newSelectedNote.startChar
			suffixMeasures = suffixMeasures.map((item) => {
				item.startChar += interval
				item.endChar += interval
				return item
			})
			measures = measures.concat(
				prefixMeasures,
				sourceMeasures,
				[newSelectedNote],
				suffixMeasures
			)
		} else if (inputMode == InputMode.Edit) {
			const newOldInterval = newSelectedNote.endChar - selectedNote.endChar
			sourceMeasures = sourceMeasures.map((item) => {
				item.startChar = newSelectedNote.startChar
				item.endChar = newSelectedNote.endChar
				return item
			})
			suffixMeasures = suffixMeasures.map((item) => {
				item.startChar += newOldInterval
				item.endChar += newOldInterval
				return item
			})
			measures = measures.concat(prefixMeasures, sourceMeasures, suffixMeasures)
		} else {
			interval = selectedNote.endChar - selectedNote.startChar
			suffixMeasures = suffixMeasures.map((item) => {
				item.startChar -= interval
				item.endChar -= interval
				return item
			})
			measures = measures.concat(prefixMeasures, suffixMeasures)
		}
		//或者对应字符串，时值信息
		const ul = 1 / parseInt(this.tuneInfo.timeSignature.split('/')[1])
		measures = measures.map((m) => {
			m.str = newAbcStr.substring(m.startChar, m.endChar)
			m.startChar === newSelectedNote.startChar &&
				m.endChar === newSelectedNote.endChar &&
				(m.isnewSelected = true)
			if (m.str) {
				const arr = this.getCharPtich(m.str)
				const pitchInfo = _.find(arr, (a) => {
					return a.isPtich === true
				})
				const { pitch } = pitchInfo
				const pitchArra = pitch.split('/')
				m.duration =
					(parseInt(pitchArra[0] || 1) / parseInt(pitchArra[1] || 1)) * ul
				m.el_type = m.el_type || 'note'
				m.type = m.str.indexOf('z') > -1 ? 'rest' : 'note'
			}
			return _.omit(m, ['abcelem', 'absEl', 'baseInfo', 'staffPos', 'svgEl'])
		})
		return measures
	}

	/**
	 * 检验小节的时值 是否等于拍数
	 */
	public checkMeasureDuration(
		selectables: any[],
		newSelectedNote: any,
		newAbcStr: string
	) {
		const result = {
			error: true,
			newAbcStr,
			selectables
		}
		const [molecule, denominator] = this.tuneInfo.timeSignature.split('/') || []
		if (!molecule || !denominator) {
			return result
		}
		// 曲谱的每小节共需要多少时值，其实就是拍数相除
		const measureDuration = parseInt(molecule) / parseInt(denominator)
		// 寻址，寻址到，后面只有休止符的音符
		const selectedNoteIndex = _.findIndex(selectables, (s) => {
			return s.isnewSelected
		})
		const prefixMeasures = selectables.slice(0, selectedNoteIndex)
		const suffixMeasures = selectables.slice(
			selectedNoteIndex + 1,
			selectables.length
		)
		const sourceMeasures = selectables.slice(
			selectedNoteIndex,
			selectedNoteIndex + 1
		)
		let point = -1
		for (let i = suffixMeasures.length - 1; i > -1; i--) {
			if (suffixMeasures[i].type !== 'rest') {
				point = i
				break
			}
		}
		let allPointmeasures: any[] = [] //需要计算时值比对的音符
		let pointMeasures = [] // 指针数组
		let noPointMeasures = [] // 非指针数据，存的应该就是小节最后的休止符
		if (point > -1) {
			pointMeasures = sourceMeasures.concat(
				suffixMeasures.slice(0, point + 1) || []
			)
			noPointMeasures = suffixMeasures.slice(point + 1, suffixMeasures.length)
		} else {
			pointMeasures = sourceMeasures
			noPointMeasures = suffixMeasures
		}
		allPointmeasures = prefixMeasures.concat(pointMeasures)
		const pointMeasureDuration = _.sumBy(allPointmeasures, (m) => m.duration)
		if (pointMeasureDuration > measureDuration) {
			return result
		} else if (pointMeasureDuration < measureDuration) {
			const l = 1 / parseInt(`${this.tuneInfo.timeSignature.split('/')[1]}`)
			let differDuration = measureDuration - pointMeasureDuration
			//let _differDuration = measureDuration - pointMeasureDuration

			const differDurationArray = []
			let diffPitch = decimal2fraction(differDuration)
			while (diffPitch.molecule !== 1) {
				differDurationArray.push(1 / diffPitch.denominator)
				differDuration -= 1 / diffPitch.denominator
				diffPitch = decimal2fraction(differDuration)
			}
			differDurationArray.push(differDuration)

			_.forEach(differDurationArray, (pitch) => {
				const newPitch = this.getNewPitch(pitch)
				const restNote = `z${newPitch} `
				const len = restNote.length
				const startChar =
					allPointmeasures.length == 0
						? newSelectedNote.startChar
						: allPointmeasures[allPointmeasures.length - 1].endChar
				const endChar =
					allPointmeasures.length == 0
						? newSelectedNote.startChar + len
						: allPointmeasures[allPointmeasures.length - 1].endChar + len

				const rest = {
					newStr: restNote,
					startChar,
					endChar,
					el_type: 'note',
					type: 'rest',
					isFillRest: true
				}
				allPointmeasures.push(rest)
			})
		}
		// 这边需要把多余的rest删除调
		const minStarChar =
			_.minBy(noPointMeasures, (p) => p.startChar)?.startChar || 0
		const maxEndChar = _.maxBy(noPointMeasures, (p) => p.endChar)?.endChar || 0
		newAbcStr = `${newAbcStr.substring(0, minStarChar)}${newAbcStr.substring(
			maxEndChar,
			newAbcStr.length
		)}`
		result.selectables = allPointmeasures
		result.newAbcStr = newAbcStr
		result.error = false
		return result
	}

	/**
	 * 计算横梁
	 * @param newSelectables 一小节的
	 * @param newSelectedNote
	 * @param newAbcStr
	 */
	public calculateMovePosition(
		newSelectables: any[],
		newSelectedNote: any,
		newAbcStr: string
	) {
		//new Beams().calculateBeams([4, 4], 8, [1, 1, 2, 1, 1, 2])
		const beams = new Beams()
		const pai = this.tuneInfo.timeSignature.split('/')
		const durations = newSelectables.map((s) => {
			return s.duration * parseInt(pai[1])
		})

		// 计算 那些区间变更为横梁
		const res =
			beams.calculateBeams(
				[parseInt(pai[0]), parseInt(pai[1])],
				parseInt(pai[1]),
				durations
			) || []
		//构建链表,判断每个音符新增空格与否
		for (let i = 0; i < newSelectables.length + 1; i++) {
			const el = newSelectables[i] || {}
			el.newStr = el.str || el.newStr
			el.newStartChar = el.startChar
			el.newEndChar = el.endChar
			el.next = newSelectables[i + 1]
			const preIndex = i - 1
			const nowIndex = i
			let needSpace = undefined
			for (let j = 0; j < res.length; j++) {
				const isPreInclude = res[j][0] <= preIndex && res[j][1] >= preIndex
				const isNextInclude = res[j][0] <= nowIndex && res[j][1] >= nowIndex
				if (isPreInclude == false && isNextInclude === false) {
					continue
				} else if (isPreInclude == true && isNextInclude === false) {
					needSpace = true
				} else if (isPreInclude == false && isNextInclude === true) {
					needSpace = true
				} else if (isPreInclude == true && isNextInclude === true) {
					needSpace = false
				}
				if (needSpace !== undefined) {
					break
				}
			}
			newSelectables[preIndex] &&
				(newSelectables[preIndex].needSpace =
					needSpace === undefined ? true : needSpace)
		}
		newSelectables.forEach((t) => {
			const needSpace = t.needSpace
			const hasSpace =
				t.newStr.substring(t.newStr.length - 1, t.newStr.length) === ' '
			let variable = 0
			if (needSpace && !hasSpace) {
				variable = 1
				t.newStr += ' '
			} else if (!needSpace && hasSpace) {
				variable = -1
				t.newStr = t.newStr.substring(0, t.newStr.length - 1)
			}
			if (variable !== 0) {
				let isFirst = true
				while (t) {
					// 第一个的的startchar 不需要更改
					!isFirst && (t.newStartChar += variable)
					t.newEndChar += variable
					t = t.next
					isFirst = false
				}
			}
		})

		const newMeasureStr = newSelectables
			.map((i) => i.newStr)
			.filter(Boolean)
			.join('')
		// const oldMeasureStr = newSelectables.map(i => i.str).filter(Boolean).join('')
		const minStarChar =
			_.minBy(
				newSelectables.filter((s) => !s.isFillRest) || [],
				(p) => p.startChar
			)?.startChar || 0
		const maxEndChar =
			_.maxBy(
				newSelectables.filter((s) => !s.isFillRest) || [],
				(p) => p.endChar
			)?.endChar || 0
		if (minStarChar !== 0 && maxEndChar !== 0) {
			newAbcStr = `${newAbcStr.substring(
				0,
				minStarChar
			)}${newMeasureStr}${newAbcStr.substring(maxEndChar, newAbcStr.length)}`
		}
		// newAbcStr = newAbcStr.replace(oldMeasureStr, newMeasureStr)
		const selectedNote = newSelectables.find((i) => i.isnewSelected) || {}
		return {
			newAbcStr,
			startChar: selectedNote.newStartChar || 0,
			endChar: selectedNote.newEndChar || 0
		}
	}

	/**
	 * 解析一个字符串的内部信息
	 * @param sourceStr
	 */
	private getCharPtich(sourceStr: string) {
		let arr = this.tokenize(sourceStr) || []
		arr = arr
			.map((item) => {
				const info: any = {
					str: item
				}
				const x = this.allPitches.indexOf(item)
				if (x > -1) {
					info.isNote = true
				}
				const res = /^(\d+)?(\/?)(\d+)/.exec(item)
				if (res) {
					info.isPtich = true
					info.pitch = res[0]
					info.matchInfo = res
				}
				return info
			})
			.filter(Boolean)
		const pitchArra = []
		for (let i = 0; i < arr.length; i++) {
			const el = arr[i]
			if (el.isPtich) {
				continue
			}
			pitchArra.push(el)
			if (el.isNote) {
				const next = arr[i + 1]
				if (next.isPtich) {
					pitchArra.push(next)
				} else {
					pitchArra.push({ isPtich: true, pitch: '1', str: '' })
				}
			}
		}
		return pitchArra
	}

	/**
	 * 时值转换分数
	 * @param duration
	 * @returns
	 */
	private getNewPitch(duration: number) {
		duration =
			duration / (1 / parseInt(`${this.tuneInfo.timeSignature.split('/')[1]}`))
		const fraction = decimal2fraction(duration)
		return `${fraction.molecule}/${fraction.denominator}`
	}
	/**
	 * 获取新的附点时值信息
	 * @param duration
	 * @returns
	 */
	private getNewDotsPitch(duration: number) {
		const relDura = _.find(this.dotsDurationTables, (item) => {
			return (
				_.findIndex(item.durations, (data) => {
					return data == duration
				}) > -1
			)
		})
		if (!relDura) {
			return ''
		}
		const duraIndex = relDura.durations.findIndex((data) => {
			return data == duration
		})
		let nowDuraIndex = 0
		let newDuration = relDura.durations[nowDuraIndex]
		let isSpection = false
		if (relDura.duration === 0.03125 && duraIndex >= 2) {
			newDuration = relDura.durations[0]
			isSpection = true
		}
		if (duraIndex < 3 && !isSpection) {
			nowDuraIndex = duraIndex + 1
			newDuration = relDura.durations[nowDuraIndex]
		}

		duration =
			newDuration /
			(1 / parseInt(`${this.tuneInfo.timeSignature.split('/')[1]}`))
		const fraction = decimal2fraction(duration)
		return `${fraction.molecule}/${fraction.denominator}`
	}

	/**
	 * 计算一个音符对应的时值音符
	 * @param letter
	 * @param timeValue
	 * @param ul
	 * @returns
	 */
	private getRealLetter(letter: string, timeValue: number, ul: number) {
		const res = timeValue / ul
		const isDivision = res > 1
		const fraction = isDivision
			? parseInt(`${res}`)
			: parseInt(`${ul / timeValue}`)
		return isDivision
			? `${letter}/${fraction}`
			: `${letter}${fraction === 1 ? '' : fraction}`
	}

	private convertToUnitLen(value: string) {
		this.tuneInfo.unitLen = `1/${value}`
	}
	private convertToSpeed(value: string) {
		this.tuneInfo.speed = value?.split('=')[1]
	}
	private replaceUnitLen() {
		return `1/${this.tuneInfo.timeSignature.split('/')[1]}`
	}

	private replaceSpeed() {
		return `1/${this.tuneInfo.timeSignature.split('/')[1]}=${this.tuneInfo
			.speed || 100}`
	}
}
