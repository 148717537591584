/*
 * @description: 状态模式，用于存个性化的操作状态 如和弦，连音等
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-05-20 16:40:10
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-03 14:48:38
 */

export enum StateMode {
  None,
  TuneInfo,
  ChordSetting,
  Ties
}