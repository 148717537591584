
import { InputMode } from "@/enum/input-mode"
import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import DataCheck from "@/utils/data-check"
import { State } from "@vue/runtime-core"
export default class BarLineAction {
  public name = 'BarLineSetting'

  execute(props: any, args: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    if (!state.isRenderTune) {
      $message.info('请先渲染出一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }
    if (state.selectedNotes.length <= 0) {
      $message.info('请选中一个小节线')
      return
    }

    const selectedNote = state.selectedNotes[0]
    const { startChar, endChar, type } = selectedNote

    if (type !== 'bar') {
      $message.info('请选中一个小节线')
      return
    }

    const abcString = state.abcString
    const tuneInfo = state.tuneInfo
    const { value } = args
    const abcStringHelper = new AbcStringHelper(tuneInfo)
    const { selectables } = $abcJsHelper as AbcJsHelper
    const result = abcStringHelper.insertOrUpdateNote(startChar, endChar, abcString, value, InputMode.Edit, selectables, selectedNote)
    if (DataCheck.$isString(result)) {
      $message.info(result)
      return
    }
    const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result as any
    $store.commit('changeAbcString', newAbcStr)
    $store.commit('clearSelectedNotes')
    if (newStartChar !== 0 && newEndChar !== 0) {
      $store.commit('changeSelectedNotes', {
        startChar: newStartChar,
        endChar: newEndChar
      })
    }
  }
}