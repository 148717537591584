/*
 * @description: 输入模式，用于来判断是输入（新增），还是修改模式，删除
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-05-20 16:37:17
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-05-20 16:43:35
 */

export enum InputMode {
  Add,
  Edit,
  Delete
}