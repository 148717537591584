/*
 * @description: OperationMode
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-18 17:30:27
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-18 17:33:28
 */


export enum OperationMode {
  Note,
  NoNote
}