/*
 * @description: DotsAction
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-16 14:23:48
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-24 17:00:27
 */

import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import DataCheck from "@/utils/data-check"
import { State } from "vue"

export default class DotsAction {
  public name = 'DotsSetting'

  execute(props: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    if (!state.isRenderTune) {
      $message.info('请先渲染出一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }
    
    if (state.selectedNotes.length <= 0) {
      $message.info('请选中一个音符')
      return
    }

    const selectedNote = state.selectedNotes[0]
    const { startChar, endChar, el_type } = selectedNote

    if (el_type !== 'note') {
      $message.info('请选中一个音符')
      return
    }
    const abcString = state.abcString
    const tuneInfo = state.tuneInfo
    const abcStringHelper = new AbcStringHelper(tuneInfo)
    const { selectables } = $abcJsHelper as AbcJsHelper
    const result = abcStringHelper.insertOrUpdateDots(startChar, endChar, abcString, selectables, selectedNote)
    if (DataCheck.$isString(result)) {
      $message.info(result)
      return
    }
    const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result as any
    $store.commit('changeAbcString', newAbcStr)
    $store.commit('clearSelectedNotes')
    if (newStartChar !== 0 && newEndChar !== 0) {
      $store.commit('changeSelectedNotes', {
        startChar: newStartChar,
        endChar: newEndChar
      })
    }
  }
}