
import { InputMode } from "@/enum/input-mode"
import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import DataCheck from "@/utils/data-check"
import { State } from "@vue/runtime-core"
export default class NotesDeleteAction {
  public name = 'NotesDelete'

  execute(props: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    const abcString = state.abcString
    const tuneInfo = state.tuneInfo
    const abcStringHelper = new AbcStringHelper(tuneInfo)
    const { selectables } = $abcJsHelper as AbcJsHelper
    if (!state.isRenderTune) {
      $message.info('请先渲染出一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }
    if (state.selectedNotes.length <= 0) {
      $message.info('请选中一个音符')
      return
    }

    const selectedNote = state.selectedNotes[0]
    const { startChar, endChar, el_type } = selectedNote

    if (el_type !== 'note') {
      $message.info('请选中一个音符')
      return
    }
    const selected = selectables.find(item => {
      return item.startChar == selectedNote.startChar && item.endChar === selectedNote.endChar
    }) || {}

    const sameMeasures = selectables?.filter(s => {
      return s.v === selected?.v && s.mm === selected?.mm && s.el_type == 'note'
    }) || []

    if (sameMeasures.length == 1 && sameMeasures[0].type === 'rest') {
      $message.info('小节只剩下一个终止符了，若不需要小节，请删除小节')
      return
    }



    $store.commit('changeInputMode', InputMode.Delete)
    const inputMode: InputMode = state.inputMode



    const result = abcStringHelper.insertOrUpdateNote(startChar, endChar, abcString, '', inputMode, selectables, selectedNote)
    if (DataCheck.$isString(result)) {
      $message.info(result)
      return
    }
    const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result as any
    $store.commit('changeAbcString', newAbcStr)
    $store.commit('clearSelectedNotes')
    if (newStartChar !== 0 && newEndChar !== 0) {
      $store.commit('changeSelectedNotes', {
        startChar: newStartChar,
        endChar: newEndChar
      })
    }
    //$store.commit('changeInputMode', InputMode.Add)

  }
}