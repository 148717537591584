/*
 * @description: RevokeAction 撤销
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-04 15:54:51
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-24 17:02:15
 */

import { StateMode } from "@/enum/state-mode";
import AbcStringHelper from "@/utils/abc/abcString-helper";
import { State } from "@vue/runtime-core";

export default class RevokeAction {
  public name = 'revoke'
  execute(props: any) {
    const { $store, $message } = props
    const state = $store.state as State
    if (!state.isRenderTune) {
      $message.info('请先渲染一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }
    const hisAbcStr = $store.getters.getLastAbcHistory()
    if (!hisAbcStr) {
      $message.info('没有上一步了')
      return
    }
    const abcStringHelper = new AbcStringHelper(state.tuneInfo)
    abcStringHelper.convertToTuneInfo(hisAbcStr)
    $store.commit('changeTuneInfo', abcStringHelper.tuneInfo)
    $store.commit('clearSelectedNotes')
    $store.commit('changeAbcString', hisAbcStr)
  }
}