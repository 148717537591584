/*
 * @description: NotesAction
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-10 09:30:35
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-07-02 13:41:30
 */


import { InputMode } from "@/enum/input-mode"
import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import DataCheck from "@/utils/data-check"
import { State } from "@vue/runtime-core"
export default class NotesAction {
  public name = 'NotesSetting'

  execute(props: any, args: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    if (!state.isRenderTune) {
      $message.info('请先渲染出一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }
    if (state.selectedNotes.length <= 0) {
      $message.info('请选中一个音符')
      return
    }

    const selectedNote = state.selectedNotes[0]
    const { startChar, endChar, el_type, type } = selectedNote

    if (el_type !== 'note') {
      $message.info('请选中一个音符')
      return
    }
    const abcString = state.abcString
    const tuneInfo = state.tuneInfo
    const { value } = args
    const abcStringHelper = new AbcStringHelper(tuneInfo)

    let inputMode: InputMode
    const { selectables } = $abcJsHelper as AbcJsHelper
    const selectStr = abcString.substring(startChar, endChar)
    let note = abcStringHelper.changeNoteTimeValue('C', value)

    if (type === 'rest') {

      //过滤同一小节的所有终止符元素
      const sameMeasures = selectables.filter(s => {
        return s.v === selectedNote.v && s.mm === selectedNote.mm && s.type == type
      }) || []
      //若是小节仅只有一个终止符，并且为选中的音符，模式默认变更为修改模式，替换该终止符
      if (sameMeasures.length === 1 && sameMeasures[0].startChar === startChar && sameMeasures[0].endChar === endChar) {
        inputMode = InputMode.Edit
      } else {
        inputMode = state.inputMode
        // inputMode == InputMode.Edit && (note = abcStringHelper.changeNoteTimeValue(selectStr, value))
      }
    } else {
      inputMode = state.inputMode
      inputMode == InputMode.Edit && (note = abcStringHelper.changeNoteTimeValue(selectStr, value))
    }
    const result = abcStringHelper.insertOrUpdateNote(startChar, endChar, abcString, `${note} `, inputMode, selectables, selectedNote)
    if (DataCheck.$isString(result)) {
      $message.info(result)
      return
    }
    const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result as any
    $store.commit('changeAbcString', newAbcStr)
    $store.commit('clearSelectedNotes')
    if (newStartChar !== 0 && newEndChar !== 0) {
      $store.commit('changeSelectedNotes', {
        startChar: newStartChar,
        endChar: newEndChar
      })
    }
  }
}