/*
 * @description: AbcJsHelper
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-04 09:41:04
 * @lastEditors: xuhuale
 * @lastEditTime: 2022-04-29 17:30:15
 */

import {
	CursorControl,
	MidiBuffer,
	renderAbc,
	synth,
	SynthObjectController,
	TuneObject
} from 'abcjs'
import _ from 'lodash'

/**
 * abc 各种参数集合
 */
interface AbcConfig {
	abcParams: any
	synthParms: any
	audioParams: any
}

export default class AbcJsHelper {
	private scollCount = 1
	protected abcString = ''
	protected config: AbcConfig | any = {}
	protected target: string
	protected audioTarget: string
	protected selectElFunc: any
	protected loadTuneEndFunc: any
	protected midiBuffer?: MidiBuffer
	public visualObj: TuneObject
	public cursorControl: CursorControl | any
	public synthControl: SynthObjectController | any
	public callBackFinished: any
	public selectables: any[] = []
	public sections: any[] = []

	constructor(
		_target: string,
		_audioTarget: string,
		_config: AbcConfig,
		selectElFunc?: any,
		loadTuneEndFunc?: any
	) {
		this.setAbcParmas()
		this.config = _.merge({}, this.config || {}, _config || {})
		this.target = _target
		this.audioTarget = _audioTarget
		this.selectElFunc = selectElFunc
		this.loadTuneEndFunc = loadTuneEndFunc
	}

	/**
	 * 加载曲谱
	 */
	public loadTune(abcString: string): void {
		console.time('loadTune')
		if (abcString) {
			this.setAbcString(abcString)
		}
		if (synth.supportsAudio()) {
			const { audioTarget } = this
			this.cursorControl = this.createCursorControl()
			this.synthControl = new synth.SynthController()
			this.synthControl.load(`#${audioTarget}`, this.cursorControl)
			this.setTune(false)
			this.loadTuneEndFunc && this.loadTuneEndFunc(this)
			console.log('supportsAudio')
		}
		console.timeEnd('loadTune')
	}

	/**
	 * 设置曲谱
	 * @param userAction
	 */
	public setTune(userAction = false): void {
		this.render()
		this.midiBuffer = new synth.CreateSynth()
		this.midiBuffer &&
			this.midiBuffer
				.init({
					...((this.config as AbcConfig).synthParms || {}),
					visualObj: this.visualObj
				})
				.then(() => {
					return this.midiBuffer?.prime()
				})
				.then(() => {
					if (this.synthControl) {
						this.synthControl.setTune(
							this.visualObj,
							userAction,
							this.config.audioParams || {}
						)
					}
				})
				.catch((err) => {
					console.warn(err)
				})
	}

	/**
	 * 播放曲谱
	 */
	public playTune() {
		if (this.synthControl) {
			this.synthControl.play()
		}
	}

	/**
	 * 停止播放
	 */
	public stopTune() {
		if (this.synthControl) {
			this.synthControl.stop()
			this.removeHightLight()
			this.deleteCursor()
			const svg = document.querySelector(`#${this.target} svg`)
			const svgParent = svg?.parentElement || {}
			;(svgParent as any).scrollTop = 0
		}
	}

	/**
	 * 播放音符
	 * @param abcElem
	 * @returns
	 */
	public playNoteMidi(abcElem: any) {
		const lastClicked = abcElem.midiPitches
		if (!lastClicked) {
      console.warn('no lastClicked')
			return
		}
		if (!this?.synthControl?.visualObj?.millisecondsPerMeasure) {
      console.warn('no millisecondsPerMeasure')
			return
		}
		synth.playEvent(
			lastClicked,
			abcElem?.midiGraceNotePitches,
			this.synthControl.visualObj.millisecondsPerMeasure()
		)
	}
	/**
	 * 创建走谱游标
	 */
	public createCursorControl() {
		const cursorControl = {
			beatSubdivisions: 2,
			//lineEndAnticipation: 500,
			onReady: () => {},
			onStart: () => {
				this.scollCount = 1
				const { target } = this
				const svg = document.querySelector(`#${target} svg`)
				const cursor = this.createOrSetCursor('0', '0', '0', '0')
				svg?.appendChild(cursor)
				const svgParent = svg?.parentElement || {}
				;(svgParent as any).scrollTop = 0
			},
			onBeat: () => {
				//beatNumber: any, totalBeats: any, totalTime: any
				//console.log('onBeat', beatNumber, totalBeats, totalTime)
			},
			onEvent: ({
				measureStart = true,
				elements = [],
				left = 0,
				top = 0,
				height = 0
			} = {}) => {
				if (measureStart && left === null) {
					return
				}
				this.removeHightLight()
				this.setHightLight(elements as any[])
				this.createOrSetCursor(
					`${left - 2}`,
					`${left - 2}`,
					`${top}`,
					`${top + height}`
				)
			},
			onFinished: () => {
				console.log('onFinished')
				this.removeHightLight()
				this.deleteCursor()
				const svg = document.querySelector(`#${this.target} svg`)
				const svgParent = svg?.parentElement || {}
				;(svgParent as any).scrollTop = 0
				this.callBackFinished && this.callBackFinished()
			},
			onLineEnd: (lineEvent: any) => {
				//leftEvent: any
				const div = document.getElementById(`${this.target}`) as HTMLElement
				const svg = document.querySelector(`#${this.target} svg`) as HTMLElement
				const pageSize = parseInt(`${svg?.scrollHeight / div?.offsetHeight}`)
				if (pageSize <= 0) {
					return
				}
				const pageHeigt = div?.offsetHeight
				const { bottom, top } = lineEvent
				if (top + bottom > pageHeigt * this.scollCount) {
					(div as any).scrollTop += pageHeigt - (bottom - top) - 110 // top
					this.scollCount++
				}
			}
		}
		cursorControl.onReady = cursorControl.onReady.bind(this)
		cursorControl.onStart = cursorControl.onStart.bind(this)
		cursorControl.onBeat = cursorControl.onBeat.bind(this)
		cursorControl.onEvent = cursorControl.onEvent.bind(this)
		cursorControl.onFinished = cursorControl.onFinished.bind(this)
		cursorControl.onLineEnd = cursorControl.onLineEnd.bind(this)

		return cursorControl
	}

	/**
	 * 渲染五线谱
	 * @returns
	 */
	public render(): TuneObject {
		console.time('render')
		const { target, config, abcString } = this
		if (!abcString) {
			console.warn('请先设置abc字符串')
			return
		}
		const div = document.getElementById(`${target}`) as HTMLElement
		const width = ((div?.clientWidth as number) - 100) / 1.5
		// const height = (div?.clientHeight as number - 40) / 1.5;
		this.visualObj = renderAbc(
			target,
			abcString,
			_.merge(
				{},
				{ staffwidth: width - 75 / 1.5 },
				{ ...(config.abcParams || {}) }
			)
		)[0] //
		this.renderSetAttribute()
		const { engraver = {} } = this.visualObj
		this.selectables = this.createSelectables(engraver.selectables)
		// console.table(this.selectables)
		console.timeEnd('render')
		return this.visualObj
	}

	/**
	 * 点击元素回调 
	 * !abcjs 注册了toch mouse 事件，会触发2次，因此在abcjs那边mouseup处理了
	 * @param abcElem
	 */
	public clickListener(
		abcelem: any,
		tuneNumber: any,
		classes: any,
		analysis: any,
		drag: any,
		mouseEvent: any
	): void {
		this.playNoteMidi(abcelem)
		this.selectElFunc &&
			this.selectElFunc(
				this.selectables,
				abcelem,
				tuneNumber,
				classes,
				analysis,
				drag,
				mouseEvent
			)
	}

	/**
	 * 解析完回调
	 * @param tune
	 * @param tuneNumber
	 * @param abcString
	 */

	public afterParsing(tune: any, tuneNumber: any, abcString: any) {
		console.log('解析完回调')
		console.log(tune)
		console.log(tuneNumber)
		console.log(abcString)
	}
	/**
	 *
	 * @param str 加载abcString
	 */
	public setAbcString(str: string): AbcJsHelper {
		this.abcString = str
		return this
	}

	public chearSelectHight() {
		const selected = document.querySelectorAll('.abcjs-note_selected') || []
		selected.forEach((s) => {
			s.setAttribute('fill', 'currentColor')
			s.classList.remove('abcjs-note_selected')
		})
	}

	/**
	 * 整理选择表格信息
	 */
	private createSelectables(selectables: any) {
		return selectables.map((item: any) => {
			const { svgEl, absEl } = item
			const { type, abcelem } = absEl
			const { el_type, startChar, endChar } = abcelem
			const classStr = svgEl.classList.value
			let v, mm
			const vreg = /abcjs-v(\d*)/
			const mmreg = /abcjs-mm(\d*)/
			const vRes = vreg.exec(classStr)
			const mmRes = mmreg.exec(classStr)
			vRes && (v = vRes[1])
			mmRes && (mm = mmRes[1])
			return {
				...item,
				type,
				el_type,
				v,
				mm,
				startChar,
				endChar,
				abcelem
			}
		})
	}

	/**
	 * 修改渲染出来的布局
	 */
	private renderSetAttribute() {
		const svg = document.querySelector(`#${this.target} svg`) as SVGElement
		const div = svg.parentElement as HTMLElement
		const vWidth = parseFloat(svg.getAttribute('width') as string)
		const vHeight = parseFloat(svg.getAttribute('height') as string)

		svg.setAttribute('viewBox', '0 0 ' + vWidth + ' ' + vHeight)
		svg.removeAttribute('width')
		svg.removeAttribute('height')
		svg.style['display'] = 'inline-block'
		svg.style['position'] = 'relative'
		svg.style['top'] = '0'
		svg.style['left'] = '0'
		div.style['display'] = 'inline-block'
		div.style['position'] = 'relative'
		div.style['verticalAlign'] = 'middle'
	}

	/**
	 * 设置渲染参数
	 */
	private setAbcParmas() {
		const { config = {}, target } = this
		;(config as AbcConfig).abcParams = _.merge(
			{},
			{
				staffwidth: document.getElementById(`${target}`)?.offsetWidth,
				add_classes: true,
				clickListener: this.clickListener
				// afterParsing: this.afterParsing
			}
		)
		//需要将clickListener的this执向改变下
		;(config as AbcConfig).abcParams.clickListener = (config as AbcConfig).abcParams.clickListener.bind(
			this
		)
		//(config as AbcConfig).abcParams.afterParsing = (config as AbcConfig).abcParams.afterParsing.bind(this)
	}

	/**
	 * 创建或设置焦点
	 * @param x1
	 * @param y1
	 * @param x2
	 * @param y2
	 * @returns
	 */
	private createOrSetCursor(
		x1?: string,
		x2?: string,
		y1?: string,
		y2?: string
	) {
		let cursor = document.querySelector(`#${this.target} svg .abcjs-cursor`)
		if (!cursor) {
			cursor = document.createElementNS('http://www.w3.org/2000/svg', 'line')
			cursor.setAttribute('class', 'abcjs-cursor')
		}
		if (x1 && y1 && x2 && y2) {
			cursor.setAttributeNS(null, 'x1', x1 as string)
			cursor.setAttributeNS(null, 'y1', y1 as string)
			cursor.setAttributeNS(null, 'x2', x2 as string)
			cursor.setAttributeNS(null, 'y2', y2 as string)
		}
		return cursor
	}

	/**
	 * 删除焦点
	 */
	private deleteCursor() {
		const cursor = document.querySelector(`#${this.target} svg .abcjs-cursor`)
		cursor?.remove()
	}

	/**
	 * 设置走放音符高亮
	 * @param elements
	 */
	private setHightLight(elements: any[]) {
		elements.forEach((el: any) => {
			el = el || []
			el?.forEach((__el: any) => {
				__el.classList.add('highlight')
			})
		})
	}

	/**
	 * 清除走放音符高亮
	 */
	private removeHightLight() {
		const lastSelections = document.querySelectorAll(
			`#${this.target} svg .highlight`
		)
		lastSelections.forEach((lastSelection) => {
			lastSelection.classList.remove('highlight')
		})
	}
}
