/*
 * @description: TuneBookModeAction 谱表模式切换
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-03 14:28:49
 * @lastEditors: xuhuale
 * @lastEditTime: 2022-04-25 14:03:11
 */
import { InputMode } from '@/enum/input-mode'
import { OperationMode } from '@/enum/operation-mode'
import { StateMode } from '@/enum/state-mode'
import { TuneBookMode } from '@/enum/tune-book-mode'
import AbcStringHelper from '@/utils/abc/abcString-helper'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue'
import { createVNode, State } from 'vue'

export default class TuneBookModeAction {
	public name = 'TuneBookModeChange'

	execute(props: any, args: any) {
		const { $store } = props
		const state = $store.state as State
		const { value } = args
		Modal.confirm({
			title: '切换谱表需要清空所有操作，重新编创,确认清空？',
			icon: createVNode(ExclamationCircleOutlined),
			centered: true,
			okText: '确认',
			cancelText: '取消',
			width: '550px',
			okButtonProps: {
				size: 'large',
				shape: 'round'
			},
			cancelButtonProps: {
				size: 'large',
				shape: 'round'
			},
			// style: {
			//   width:'500px !important',
			//   fontSize: '18px !important'
			// },
			onOk() {
				$store.commit('changeTuneBookMode', value)
				if (!state.isRenderTune) {
					return
				}
				// 清空所有操作，重新初始化曲谱
				$store.commit('changeInputMode', InputMode.Add)
				$store.commit('changeStateMode', StateMode.None)
				$store.commit('changeOpeartionMode', OperationMode.Note)
				$store.commit('clearAbcHistory')
				$store.commit('clearabcRecoveryHistory')
				$store.commit('changeIsRenderTune', false)
				$store.commit('clearSelectedNotes')
				state.tuneInfo.speed = 100
				const abcStringHelper = new AbcStringHelper(state.tuneInfo)
				const abcString =
					state.tuneBookMode === TuneBookMode.Single
						? abcStringHelper.newSingleAbcString()
						: abcStringHelper.newDoucleAbcString()
				$store.commit('changeAbcString', abcString)
			}
		})
	}
}
