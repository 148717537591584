
import _ from 'lodash'
import { computed, defineComponent, PropType, ref, watch } from 'vue'
export default defineComponent({
	name: 'double-state-select',
	props: {
		valueModel: String,
		display: String,
		options: {
			requird: true,
			type: Array as PropType<Array<any>>
		},
		disabled: {
			type: Boolean,
			default: true
		}
	},
	setup(props, ctx) {
		// 有值状态
		const state = ref(false)
		let model = ref(_.cloneDeep(props.valueModel))

		watch(
			() => props.valueModel,
			(newVal) => {
				model.value = newVal
			}
		)
		// 监听绑定值
		watch(model, (newVal) => {
			state.value = !!newVal
			ctx.emit('update:valueModel', newVal)
		})

		// 显示隐藏选项
		const displayOpt = ref(false)
		const onDisplayViewClick = () => {
			if (props.disabled) {
				displayOpt.value = !displayOpt.value
			}
		}
		// model.value = (props.options as Array<any>)[2].value
		const onOptClick = (value: any) => {
			model.value = value
			displayOpt.value = false
		}
		// 获取选中的选项
		const selectOpt = computed<any>(() => {
			const opt = props.options?.find((i: any) => i.value === model.value)
			// if (!opt) {
			//     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
			//     model.value = undefined
			// }
			return opt || {}
		})
		return {
			model,
			state,
			selectOpt,
			displayOpt,
			onDisplayViewClick,
			onOptClick
		}
	}
})
