/*
 * @description: 临时记号
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-11 10:09:50
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-24 16:59:41
 */

import { InputMode } from "@/enum/input-mode"
import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import DataCheck from "@/utils/data-check"
import { State } from "vue"

export default class AccidentalsAction {
  public name = 'AccidentalsSetting'
  execute(props: any, args: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    if (!state.isRenderTune) {
      $message.info('请先渲染出一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }
    if (state.selectedNotes.length <= 0) {
      $message.info('请选中一个音符')
      return
    }
    const selectedNote = state.selectedNotes[0]
    const { startChar, endChar, el_type, type } = selectedNote
    const abcString = state.abcString
    const tuneInfo = state.tuneInfo
    const { value, mutex = [] } = args
    const abcStringHelper = new AbcStringHelper(tuneInfo)
    if (el_type !== 'note') {
      $message.info('请选中一个音符')
      return
    }
    if (type === 'rest') {
      // 临时记号对休止符不起作用
      $message.warning('临时记号不能作用于终止符')
      return
    }
    const sourceStr = abcString.substring(startChar, endChar)
    const isChord = sourceStr.indexOf('[') > -1
    if (isChord) {
      //和弦也不起作用
      $message.warning('临时记号不能作用于和弦')
      return
    }
    const { selectables } = $abcJsHelper as AbcJsHelper
    const note = abcStringHelper.changeAccidentals(sourceStr, value, mutex)
    const result = abcStringHelper.insertOrUpdateNote(startChar, endChar, abcString, note, InputMode.Edit, selectables, selectedNote)
    if (DataCheck.$isString(result)) {
      $message.info(result)
      return
    }
    const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result as any
    $store.commit('changeAbcString', newAbcStr)
    $store.commit('clearSelectedNotes')
    if (newStartChar !== 0 && newEndChar !== 0) {
      $store.commit('changeSelectedNotes', {
        startChar: newStartChar,
        endChar: newEndChar
      })
    }
  }
}