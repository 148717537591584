
import AudioBar from '@/components/music-score/audio-bar.vue'
import ChordSetting from '@/components/music-score/chord-setting.vue'
import toolBar from '@/components/music-score/tool-bar.vue'
import TuneBook from '@/components/music-score/tune-book.vue'
import TuneInfoSetting from '@/components/music-score/tune-info-setting.vue'
import { StateMode } from '@/enum/state-mode'
import useCurrentInstance from '@/hook/useCurrentInstance'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
	components: { toolBar, TuneBook, AudioBar, TuneInfoSetting, ChordSetting },
	name: 'music-score',
	setup() {
		const instance = useCurrentInstance()
		const store = useStore()
		const onChangeChordSetting = (chordString: string): void => {
			// 执行新增和弦的指令
			instance?.$command.execAction('ChordSetting', chordString)
		}
		const onChangeTuneInfoSetting = (
			tuneInfo: any,
			isChangeTimeSignture: boolean
		): void => {
			// 执行保存基础信息的指令
			instance?.$command.execAction('TuneInfoSetting', {
				tuneInfo,
				isChangeTimeSignture
			})
		}
		const onClose = () => {
			store.commit('changeStateMode', StateMode.None)
		}

		const selectedClef = computed<string>(() => {
			let _clef = 'treble'
			const selectedNote = store.state.selectedNotes[0]
			if (selectedNote.v === '1') {
				_clef = 'bass'
			}
			return _clef
		})
		return {
			onChangeChordSetting,
			onChangeTuneInfoSetting,
			onClose,
			StateMode,
			selectedClef
		}
	}
})
