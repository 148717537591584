
import _ from 'lodash'
import useCurrentInstance from '@/hook/useCurrentInstance'
import AbcJsHelper from '@/utils/abc/abcjs-helper'
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons-vue'
import { defineComponent, ref, State, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
	name: 'audio-bar',
	components: { PlayCircleFilled, PauseCircleFilled },
	setup() {
		let isPlay = ref(false)
		let playText = ref('播放')
		const instance = useCurrentInstance()
		const store = useStore()
		const { $abcJsHelper = {}, emitter } = instance || {}
		const onPlayClick = () => {
			isPlay.value = !isPlay.value
			isPlay.value ? (playText.value = '停止') : (playText.value = '播放')
			isPlay.value
				? ($abcJsHelper as AbcJsHelper).playTune()
				: ($abcJsHelper as AbcJsHelper).stopTune()
			;($abcJsHelper as AbcJsHelper).callBackFinished = () => {
				isPlay.value = false
				isPlay.value ? (playText.value = '停止') : (playText.value = '播放')
			}
		}
		const tuneInfo = toRaw(store.state.tuneInfo as any)
		watch(
			() => store.state.tuneInfo.speed,
			(val: any) => {
				if (val) {
					speed.value = val
				}
			}
		)
		const speed = ref(tuneInfo.speed || 100) //tuneInfo.speed ||
		const speedData = [60, 70, 80, 90, 100, 110, 120, 130, 140, 150]
		const onChangeSpeed = (value: any) => {
			isPlay.value = false
			isPlay.value ? (playText.value = '停止') : (playText.value = '播放')
			instance?.$command.execAction('TuneInfoSetting', {
				tuneInfo: _.merge(_.cloneDeep(store.state.tuneInfo), {
					speed: value
				})
			})
		}
		/**
		 * 注册事件 用于更改显示的文字，不用vuex的原因，是要更改的属性比较靠组件，并不是全app共用
		 */
		emitter.on('audio-bar:change-playInfo', (_isPlay: boolean) => {
			isPlay.value = _isPlay
			isPlay.value ? (playText.value = '停止') : (playText.value = '播放')
		})

		const onSaveAndExit = () => {
			const jsBridge = window.WebViewJavascriptBridge
			const { abcString, filePath, tuneInfo } = store.state as State
			jsBridge &&
				jsBridge.callHandler('saveAndBack', {
					sheetText: abcString,
					name: tuneInfo.title
				})
		}
		const onExit = () => {
			const jsBridge = window.WebViewJavascriptBridge
			jsBridge && jsBridge.callHandler('back')
		}
		return {
			isPlay,
			playText,
			onPlayClick,
			speed,
			speedData,
			onChangeSpeed,
			onSaveAndExit,
			onExit
		}
	}
})
