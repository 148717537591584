/*
 * @description: TuneBookInitAction 初始化曲谱。用来加载默认曲谱 还有缓存中的信息
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-07 16:07:28
 * @lastEditors: xuhuale
 * @lastEditTime: 2022-04-29 16:54:26
 */
import _ from 'lodash'
import { InputMode } from '@/enum/input-mode'
import { OperationMode } from '@/enum/operation-mode'
import { StateMode } from '@/enum/state-mode'
import { TuneBookMode } from '@/enum/tune-book-mode'
import AbcStringHelper from '@/utils/abc/abcString-helper'
import { State } from 'vue'

const getUser = () => {
	return new Promise((resolve, reject) => {
		const jsBridge = window.WebViewJavascriptBridge
		if (!jsBridge) {
			resolve({})
			return
		}
		jsBridge.callHandler('getUser', '', (res: any) => {
			const result = JSON.parse(res) || {}
			if (result.eventCode > 0) {
				resolve(result.data)
			} else {
				reject(new Error('callHandler get user faild'))
			}
		})
	})
}
export default class TuneBookInitAction {
	public name = 'TuneBookInit'
	execute(props: any, args: any) {
		const { $store, bootConfig } = props
		const state = $store.state as State
		console.log(`isRenderTune ${state.isRenderTune}`)
		console.log(
			`has WebViewJavascriptBridge ${!!window.WebViewJavascriptBridge}`
		)

		const loadFile = async (filePath: string) => {
			// 清空旧的信息
			$store.commit('changeInputMode', InputMode.Add)
			$store.commit('changeStateMode', StateMode.None)
			$store.commit('changeOpeartionMode', OperationMode.Note)
			$store.commit('changeAbcString', '') // 清屏
			$store.commit('changeIsRenderTune', false)
			let abcString = ''
			if (!filePath || filePath === '') {
				// 代表是新增
				const user = await getUser() as any
				$store.commit(
					'changeTuneInfo',
					_.merge(state.tuneInfo || {}, bootConfig.defaultTuneInfo || {}, {
						author: user.arranger
					})
				)
				const tuneInfo = state.tuneInfo
				const abcStringHelper = new AbcStringHelper(tuneInfo)
				abcString =
					state.tuneBookMode === TuneBookMode.Single
						? abcStringHelper.newSingleAbcString()
						: abcStringHelper.newDoucleAbcString()
			} else {
				// 代表是修改
				try {
					abcString = await (await fetch(filePath)).text()
				} catch (e) {
					console.log('fetch abc file failed', e)
					abcString = ''
				}
				const abcStringHelper = new AbcStringHelper({})
				abcStringHelper.convertToTuneInfo(abcString)
				$store.commit('changeTuneInfo', abcStringHelper.tuneInfo)
			}
			let isSingle = true
			const reg = new RegExp(/\[V:2\]/)
			isSingle = !reg.test(abcString)
			$store.commit('clearAbcHistory')
			$store.commit('clearabcRecoveryHistory')
			$store.commit('clearSelectedNotes')
			$store.commit(
				'changeTuneBookMode',
				isSingle ? TuneBookMode.Single : TuneBookMode.Double
			)

			$store.commit('changeAbcString', abcString)
		}
		if (!state.isRenderTune) {
			//判断是否在原生app环境当中
			if (window.WebViewJavascriptBridge) {
				const jsBridge = window.WebViewJavascriptBridge
				jsBridge.registerHandler(
					'editorLoadFile',
					async (
						response: string,
						responseCallback: (status: boolean) => void
					) => {
						const data = JSON.parse(response).data || {}
						const { filePath } = data
						try {
							await loadFile(filePath)
							responseCallback && responseCallback(true)
						} catch (e) {
							responseCallback && responseCallback(false)
						}
					}
				)
				jsBridge.callHandler('noticeInitState', { state: true })
			} else {
				//! test codes
				// loadFile('/test/test.txt')
				// return
				getUser().then((user: any) => {
					$store.commit(
						'changeTuneInfo',
						_.merge(state.tuneInfo || {}, bootConfig.defaultTuneInfo || {}, {
							author: user.arranger
						})
					)
					const tuneInfo = state.tuneInfo
					const abcStringHelper = new AbcStringHelper(tuneInfo)
					const abcString =
						state.tuneBookMode === TuneBookMode.Single
							? abcStringHelper.newSingleAbcString()
							: abcStringHelper.newDoucleAbcString()

					$store.commit('changeAbcString', abcString)
				})
			}
		}
	}
}
