/*
 * @description: InputModeAction
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-03 13:53:55
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-03 16:32:14
 */

export default class InputModeAction {
  public name = 'InputModeChange'

  execute(props: any, args: any) {
    const { $store } = props
    const { value } = args
    $store.commit('changeInputMode', value)
  }
}