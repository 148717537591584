/*
 * @description: loadTuneEndFunc 渲染结束回调，用于更新选中的音符，并且播放音频,为什么不在内部做，是因为不想破坏abcjshelper，增加vuex的业务
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-10 15:00:18
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-07-02 09:33:25
 */

import store from "@/store"
import { State } from "@vue/runtime-core"
import AbcJsHelper from "./abcjs-helper"

function clearSelection(engraver: any) {
  for (let i = 0;i < engraver.selected.length;i++) {
    engraver.selected[i].unhighlight(undefined, engraver.renderer.foregroundColor);
  }
  engraver.selected = [];
}
const loadTuneEndFunc = (abcjsHelper: AbcJsHelper) => {
  const { selectables, visualObj } = abcjsHelper
  const state = store.state as unknown as State
  let { selectedNotes } = state
  clearSelection(visualObj.engraver)
  selectedNotes = selectedNotes.map(note => {
    if (note.type) {
      return note
    }
    const selectedNote = selectables.find(item => {
      return item.startChar == note.startChar && item.endChar === note.endChar
    }) || {}
    setTimeout(() => {
      selectedNote.abcelem && selectedNote.abcelem.abselem.highlight(undefined, visualObj.engraver.selectionColor)
      selectedNote.abcelem && visualObj.engraver.selected.push(selectedNote.abcelem.abselem)
      abcjsHelper.playNoteMidi(selectedNote.abcelem)
    }, 100)
    return {
      ...note,
      ...selectedNote
    }
  })
  if (selectedNotes.length === 0) {
    selectedNotes = [selectables.find(s => s.v === '0' && s.mm == '0' && s.el_type === 'note')]
    const selectedNote = selectedNotes[0]
    selectedNote && selectedNote.abcelem && selectedNote.abcelem.abselem.highlight(undefined, visualObj.engraver.selectionColor)
    selectedNote && selectedNote.abcelem && visualObj.engraver.selected.push(selectedNote.abcelem.abselem)
  }
  store.commit('changeAllSelectedNotes', selectedNotes)
}

export default loadTuneEndFunc