/*
 * @description: 监听状态管理的值
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-03 16:26:17
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-24 11:29:48
 */

import { InputMode } from '@/enum/input-mode'
import { OperationMode } from '@/enum/operation-mode'
import AbcjsHelper from '@/utils/abc/abcjs-helper'
import { App, nextTick, State, watch } from 'vue'
import { Store } from 'vuex'

const watchStore = (app: App, store: Store<any>) => {
  const state = store.state as State
  const abcjsHelper = app.config.globalProperties.$abcJsHelper as AbcjsHelper
  const bootConfig = app.config.globalProperties.bootConfig || {}
  const emitter = app.config.globalProperties.emitter || {}

  watch(() => state.abcString, (newStr) => {
    /* 由于可能针对一个音符一直修改同一个音符，导致abc字符串并没有发生变化，这边在store的赋值增加了随机数，
    这边去除了随机数，这样子保证用户每次操作,即时真实字符串没有修改，也能渲染
    */
    newStr = newStr.replace(/【(\d+)】/, '')
    nextTick(() => {
      bootConfig.debug && console.log('abcString被更改了', newStr)
      if (abcjsHelper.synthControl && abcjsHelper.synthControl.isStarted) {
        // 触发音频停止，并修改按钮文字，及其状态
        abcjsHelper.stopTune()
        emitter.emit('audio-bar:change-playInfo', false)
      }
      // 监听了abcstring 由这边统一渲染
      abcjsHelper.loadTune(newStr)
      store.commit('changeVisualObject', abcjsHelper.visualObj)
      store.commit('changeSynthControl', abcjsHelper.synthControl)
      abcjsHelper.visualObj ? store.commit('changeIsRenderTune', true) : store.commit('changeIsRenderTune', false)
      if (state.opeartionMode === OperationMode.Note) {
        store.commit('clearabcRecoveryHistory')
      }
      store.commit('changeAbcHistory', newStr)
      if ((store.state as State).inputMode !== InputMode.Add) {
        store.commit('changeInputMode', InputMode.Add)
      }
      // 暂时保存变量，以便在console查看
      !bootConfig.abcjs && (bootConfig.abcjs = {})
      bootConfig.abcjs.visualObj = abcjsHelper.visualObj
      bootConfig.abcjs.synthControl = abcjsHelper.synthControl
      bootConfig.abcjs.abcString = newStr

    })
  })
}
export default watchStore