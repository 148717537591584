/*
 * @description: RefreshAction 重做
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-04 16:28:17
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-24 17:02:08
 */

import { StateMode } from "@/enum/state-mode"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import { State } from "vue"

export default class RecoveryAction {
  public name = 'Recovery'

  execute(props: any) {
    const { $store, $message } = props
    const state = $store.state as State
    if (!state.isRenderTune) {
      $message.info('请先渲染一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }
    const hisAbcStr = $store.getters.getLastRecoveryHistory()
    if (!hisAbcStr) {
      $message.info('已经是最新')
      return
    }
    const abcStringHelper = new AbcStringHelper(state.tuneInfo)
    abcStringHelper.convertToTuneInfo(hisAbcStr)
    $store.commit('changeTuneInfo', abcStringHelper.tuneInfo)
    $store.commit('clearSelectedNotes')
    $store.commit('changeAbcString', hisAbcStr)
    // Modal.confirm({
    //   title: '重做需要清空所有操作，重新编创,确认清空？',
    //   icon: createVNode(ExclamationCircleOutlined),
    //   okText: '确认',
    //   cancelText: '取消',
    //   onOk() {
    //     // 清空所有操作，重新初始化曲谱
    //     $store.commit('changeInputMode', InputMode.Add)
    //     $store.commit('clearAbcHistory')
    //     $store.commit('changeIsRenderTune', false)
    //     const abcStringHelper = new AbcStringHelper(state.tuneInfo)
    //     const abcString = state.tuneBookMode === TuneBookMode.Single ? abcStringHelper.newSingleAbcString() : abcStringHelper.newDoucleAbcString()
    //     $store.commit('changeAbcString', abcString)

    //   }
    // })
    //$store.commit('changeAbcString', hisAbcStr)
  }
}