/*
 * @description: NotePitchAction
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-15 11:29:41
 * @lastEditors: xuhuale
 * @lastEditTime: 2022-04-25 14:57:46
 */

import { StateMode } from '@/enum/state-mode'
import AbcStringHelper from '@/utils/abc/abcString-helper'
import { State } from 'vue'

export default class NotePitchAction {
	public name = 'NotePitchSetting'

	execute(props: any, args: any) {
		const { $store, $message } = props
		const state = $store.state as State
		if (!state.isRenderTune) {
			$message.info('请先渲染出一个曲谱出来')
			return
		}
		if (state.stateMode === StateMode.Ties) {
			$store.commit('changeStateMode', StateMode.None)
		}
		if (state.selectedNotes.length <= 0) {
			$message.info('请选中一个音符')
			return
		}

		const selectedNote = state.selectedNotes[0]
		const { startChar, endChar, el_type, type } = selectedNote

		if (el_type !== 'note' || type !== 'note') {
			$message.info('请选中一个音符')
			return
		}

		const abcString = state.abcString
		const tuneInfo = state.tuneInfo
		const { value: setp } = args
		const abcStringHelper = new AbcStringHelper(tuneInfo)
		const result = abcStringHelper.changeNotePitch(
			startChar,
			endChar,
			abcString,
			setp
		)
		const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result
		$store.commit('changeAbcString', newAbcStr)
		$store.commit('clearSelectedNotes')
		if (newStartChar !== 0 && newEndChar !== 0) {
			$store.commit('changeSelectedNotes', {
				startChar: newStartChar,
				endChar: newEndChar
			})
		}
	}
}
