import _ from 'lodash'
import { InputMode } from '@/enum/input-mode'
import { StateMode } from '@/enum/state-mode'
import { TuneBookMode } from '@/enum/tune-book-mode'
import { createStore } from 'vuex'
import { OperationMode } from '@/enum/operation-mode'

export default createStore({
  state: {

    /**
     * 输入模式
     */
    inputMode: InputMode.Add,
    /***
     * 状态模式
     */
    stateMode: StateMode.None,
    /**
     * 曲谱模式
     */
    tuneBookMode: TuneBookMode.Single,
    /**
     * abc渲染字符串
     */
    abcString: '',
    /**
     * abc渲染字符串历史记录
     */
    abcHistory: new Array<any>(),
    /**
     * 恢复操作记录
     */
    abcRecoveryHistory: new Array<any>(),
    /**
     * 用于判断 恢复操作记录是否要全部清掉
     */
    opeartionMode: OperationMode.Note,
    /**
     * 选中的note
     */
    selectedNotes: new Array<any>(),
    /**
     * 页面是否渲染了Tune
     */
    isRenderTune: false,
    /**
     * abcjs 渲染出来的对象 
     */
    visualObject: new Array<any>(),
    /**
     * 音频合成 控制器
     */
    synthControl: null,
    /**
     * 曲谱信息
     */
    tuneInfo: {},
    /**
     * 曲谱文件路径
     */
    filePath: null
  },
  mutations: {
    changeInputMode(state, value: InputMode) {
      state.inputMode = value
    },
    changeStateMode(state, value: StateMode) {
      state.stateMode = value
    },
    changeTuneBookMode(state, value: TuneBookMode) {
      state.tuneBookMode = value
    },
    changeAbcString(state, value) {
      value = value.replace(/【(\d+)】/, '')
      state.abcString = `${value}【${Math.ceil(Math.random() * 10)}】`//value
    },
    changeAbcHistory(state, value) {
      const len = window.bootConfig.allowAbcHistoryLen || 10 //允许回退几步，默认1-步
      if (state.abcHistory.length + 1 > len) {
        state.abcHistory.splice(0, 1) //删除第一个
      }
      state.abcHistory.push(value)
    },
    changeabcRecoveryHistory(state, value) {
      state.abcRecoveryHistory.push(value)
    },
    clearAbcHistory(state) {
      state.abcHistory = []
    },
    clearabcRecoveryHistory(state) {
      state.abcRecoveryHistory = []
    },
    changeOpeartionMode(state, value: OperationMode) {
      state.opeartionMode = value
    },
    changeSelectedNotes(state, value) {
      state.selectedNotes.push(value)
    },
    changeAllSelectedNotes(state, value) {
      state.selectedNotes = value
    },
    changeIsRenderTune(state, value) {
      state.isRenderTune = value
    },
    changeVisualObject(state, value) {
      state.visualObject = value
    },
    changeSynthControl(state, value) {
      state.synthControl = value
    },
    changeFilePath(state, value) {
      state.filePath = value
    },
    clearSelectedNotes(state) {
      state.selectedNotes.length = 0

    },
    clearSurplusSelectedNotes(state) {
      state.selectedNotes = state.selectedNotes.slice(state.selectedNotes.length - 1, state.selectedNotes.length)
    },
    clearSpecificSelectedNotes(state, removeObj) {
      _.remove(state.selectedNotes || [], function (s) {
        return s.startChar === removeObj.startChar && s.endChar === removeObj.endChar
      });
    },
    changeTuneInfo(state, value) {
      state.tuneInfo = value
    },
  },
  getters: {
    /**
     * 获取最近一步
     * @param state 
     * @returns 
     */
    getLastAbcHistory: (state) => () => {
      if (state.abcHistory.length <= 1) {
        return
      }
      const recovetyStr = state.abcHistory[state.abcHistory.length - 1]
      state.abcRecoveryHistory.push(recovetyStr) //压入恢复栈
      const lastHistory = state.abcHistory[state.abcHistory.length - 2]
      state.abcHistory.splice(state.abcHistory.length - 2, 2)
      return lastHistory
    },
    /**
     * 获取最近一步
     * @param state 
     * @returns 
     */
    getLastRecoveryHistory: (state) => () => {
      if (state.abcRecoveryHistory.length <= 0) {
        return
      }
      const lastHistory = state.abcRecoveryHistory[state.abcRecoveryHistory.length - 1]
      state.abcRecoveryHistory.splice(state.abcRecoveryHistory.length - 1, 1)
      return lastHistory
    }
  },
  actions: {
  },
  modules: {
  }
})
