/*
 * @description: Command
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-02 15:11:23
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-29 09:20:28
 */


import store from '@/store'
import DataCheck from '@/utils/data-check'
import { App } from 'vue'
import * as actions from './actions'
export default class Command {

  public readonly commandActions: any = {}
  public readonly app: any
  constructor(app: App) {
    this.app = app
    this.createCommandActions()
  }
  private createCommandActions() {
    Object.entries(actions || {}).forEach(([, value]) => {
      if (DataCheck.$isFunction(value)) {
        const action = new value()
        const name = action.name
        this.commandActions[name] = action
      }
    })
  }

  /**
   * 执行动作
   * 因为可能有些动作是一套的 ，有个先后顺序，比如弹窗，弹窗一个动作，点击确定一个动作，
   * 所以封装了before，after，调用命令可自行调整成生命周期
   * @param name 
   * @param args 
   */
  execAction(name: string, args: any = {}) {
    if (!name) {
      console.warn('请配置action')
      return
    }
    const names = name.split('.')
    const action = this.commandActions[names[0]]
    if (action) {
      console.time(`op-${name}`)
      const props = this.app.config.globalProperties
      let func = null
      if (names.length > 0) {
        const funcName = names[1]
        if (funcName === 'before') {
          func = action.beforeExecute
        } else if (funcName === 'after') {
          func = action.afterExecute
        } else {
          func = action.execute
        }
      } else {
        func = action.execute
      }
      if (DataCheck.$isFunction(func)) {
        store.commit('changeOpeartionMode', args.opeartionMode || 0)
        func(props, args)
      } else {
        console.warn(`[${action.name}]commandAction,请实现对应的方法`)
      }
      console.timeEnd(`op-${name}`)
    } else {
      console.warn(`并没有注册[${names[0]}]action`)
    }
  }
}