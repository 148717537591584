/*
 * @description: MeasureAction 小节操作
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-11 16:26:35
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-25 09:36:12
 */

import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import _ from "lodash"
import { State } from "vue"


export default class MeasureAction {
  public name = 'MeasureSetting'

  execute(props: any, args: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    if (!state.isRenderTune) {
      $message.info('请先渲染出一个曲谱出来')
      return
    }
    if (state.stateMode === StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.None)
    }

    if (state.selectedNotes.length <= 0) {
      $message.info('请选中一个小节线')
      return
    }

    const selectedNote = state.selectedNotes[0]
    const { type } = selectedNote

    if (type !== 'bar') {
      $message.info('请选中一个小节线')
      return
    }

    const abcString = state.abcString
    const tuneInfo = state.tuneInfo
    const { value } = args// 1 代表新增 2代表删除
    const { selectables } = $abcJsHelper as AbcJsHelper
    if (value === 2) {
      //删除之前判断下是否只剩下一个小节，若是不能删除
      const sameMM = _.groupBy(selectables, 'mm')
      const len = Object.keys(sameMM).length
      if (len <= 1) {
        $message.info('至少需要一小节')
        return
      }
    }
    const abcStringHelper = new AbcStringHelper(tuneInfo)
    const result = abcStringHelper.insertOrUpdateMeasure(selectedNote, abcString, selectables, value, state.tuneBookMode)
    const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result
    $store.commit('changeAbcString', newAbcStr)
    $store.commit('clearSelectedNotes')
    if (newStartChar !== 0 && newEndChar !== 0) {
      $store.commit('changeSelectedNotes', {
        startChar: newStartChar,
        endChar: newEndChar
      })
    }

  }
}