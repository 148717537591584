
import useCurrentInstance from '@/hook/useCurrentInstance'
import { defineComponent, nextTick, onMounted } from 'vue'
export default defineComponent({
    name: 'tune-book',
    setup() {
        const instance = useCurrentInstance()
        onMounted(() => {
            const timer = setInterval(() => {
                // 判断文档和所有子资源(图片、音视频等)已完成加载
                if (document.readyState === 'complete') {
                    window.clearInterval(timer)
                    instance?.$command.execAction('TuneBookInit')
                }
            }, 500)
        })
        return
    },
})
