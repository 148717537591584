import { createApp, reactive } from 'vue'
import mitt from 'mitt'
import Antd, { message } from 'ant-design-vue';
import App from './App.vue'
import store from './store'
import utilObj from './utils'
import Command from './command/command'
import watchStore from './store/watch-store'
import AbcJsHelper from './utils/abc/abcjs-helper'
import selection from './utils/abc/abcjs-selection'
import loadTuneEndFunc from './utils/abc/abcjs-loadTuneEnd'
import version from '../version'
import './utils/rem'

import "ant-design-vue/dist/antd.css";

// 使用插件
const app = createApp(App)
  .use(store).
  use(Antd)

// 创建系统配置的响应化
const bootConfig = reactive(window.bootConfig || {})

// 扩展方法
Object.entries(utilObj).forEach(([key, value]) => {
  app.config.globalProperties[key] = value
})
app.config.globalProperties.bootConfig = window.bootConfig = bootConfig
app.config.globalProperties.$command = new Command(app)
app.config.globalProperties.$abcJsHelper = new AbcJsHelper(bootConfig.abc?.target, bootConfig.abc?.audioTarget, bootConfig.abc?.config, selection, loadTuneEndFunc)
app.config.globalProperties.emitter = mitt()


//监听store 状态共享
watchStore(app, store)

//全局配置下消息提醒
message.config({
  top: `100px`,
  duration: 3,
  maxCount: 3,
});
window.$app = app
// 挂载节点
app.mount('#app')
console.log(`当前版本${version}`)
