/*
 * @description: TuneInfoSettingAction  曲谱基本设置action
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-02 15:14:35
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-07-05 11:01:21
 */

import { InputMode } from "@/enum/input-mode"
import { OperationMode } from "@/enum/operation-mode"
import { StateMode } from "@/enum/state-mode"
import { TuneBookMode } from "@/enum/tune-book-mode"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import { State, toRaw } from "@vue/runtime-core"
import { Store } from "vuex"

export default class TuneInfoAction {
  public name = 'TuneInfoSetting'

  beforeExecute(props: any) {
    const { $store } = props
    $store.commit('changeStateMode', StateMode.TuneInfo)
  }

  execute(props: any, args: any) {
    const $store = props.$store as Store<any>
    const state = $store.state as State
    const isRenderTune = state.isRenderTune
    const { tuneInfo, isChangeTimeSignture = false } = args
    if (!tuneInfo || toRaw(state.tuneInfo) === tuneInfo) {
      return
    }
    $store.commit('changeTuneInfo', tuneInfo)

    const abcStringHelper = new AbcStringHelper(tuneInfo)
    if (!isRenderTune || isChangeTimeSignture) {
      //没有初始化，初始化字符串渲染
      $store.commit('changeInputMode', InputMode.Add)
      $store.commit('changeStateMode', StateMode.None)
      $store.commit('changeOpeartionMode', OperationMode.Note)
      // $store.commit('clearAbcHistory')
      // $store.commit('clearabcRecoveryHistory')
      $store.commit('clearSelectedNotes')
      const abcString = state.tuneBookMode === TuneBookMode.Single ? abcStringHelper.newSingleAbcString() : abcStringHelper.newDoucleAbcString()
      $store.commit('changeAbcString', abcString)
    } else {
      // 替换当前的字符串
      $store.commit('clearSelectedNotes')
      const abcString = abcStringHelper.updateTuneInfo(toRaw(state.abcString))
      $store.commit('changeAbcString', abcString)

    }
    $store.commit('changeStateMode', StateMode.None)
  }
}