/*
 * @description: decimal2fraction
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-16 14:09:01
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-16 14:22:55
 */

/**
 * 转换成分数
 * @param num 
 * @returns 
 */
const decimal2fraction = (num: number) => {
  const hcfof = (num1: any, num2: any) => {
    let firnum, secnum
    if (num1 < num2) {
      firnum = num1
      secnum = num2
    }
    else {
      firnum = num2
      secnum = num1
    }
    let rem = (secnum % firnum)
    while (rem > 0) {
      secnum = firnum
      firnum = rem;
      rem = (secnum % firnum)
    }
    const hcf = firnum
    return hcf
  }

  const integer = Math.floor(num)
  const decimal = num - integer
  let exponent = 1
  if (decimal !== 0) {
    exponent = Math.pow(10, num.toString().length - 2)
  }
  let maxexponent = Math.round(num * exponent)
  const hcf = hcfof(maxexponent, exponent)
  maxexponent = maxexponent / hcf
  exponent = exponent / hcf
  return {
    molecule: maxexponent,
    denominator: exponent
  }
}
export default decimal2fraction