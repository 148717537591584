/*
 * @description: ChordSettingAction
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-02 17:27:14
 * @lastEditors: xuhuale
 * @lastEditTime: 2022-04-25 17:34:49
 */

import { StateMode } from "@/enum/state-mode"
import AbcJsHelper from "@/utils/abc/abcjs-helper"
import AbcStringHelper from "@/utils/abc/abcString-helper"
import { State } from "vue"
export default class TiesAction {
  public name = 'TiesSetting'

  beforeExecute(props: any) {
    const { $store, $message, $abcJsHelper } = props
    const { state } = $store
    if (!state.isRenderTune) {
      $message.info('请先渲染出一个曲谱出来')
      return
    }
    if (state.stateMode !== StateMode.Ties) {
      $store.commit('changeStateMode', StateMode.Ties)
      $store.commit('clearSelectedNotes');
      ($abcJsHelper as AbcJsHelper).chearSelectHight()
      $message.info('请分别选中2个音符作连音的起点，终点')

    } else {
      $store.commit('changeStateMode', StateMode.None)
      // 连音线需要删除选中的音符，只保留一个
      $store.commit('clearSurplusSelectedNotes');
      // $store.commit('clearSelectedNotes');
      // ($abcJsHelper as AbcJsHelper).chearSelectHight()

    }
  }

  execute(props: any) {
    const { $store, $message, $abcJsHelper } = props
    const state = $store.state as State
    const curSelectedNote = state.selectedNotes[state.selectedNotes.length - 1] || {}
    const { type } = curSelectedNote
    if (type !== 'note') {
      $message.info('当前选着的并不是音符，请重新选择')
      $store.commit('clearSelectedNotes')
      $store.commit('changeStateMode', StateMode.None);
      ($abcJsHelper as AbcJsHelper).chearSelectHight()
    }
    if (state.stateMode === StateMode.Ties && state.selectedNotes.length == 2) {
      const [selectedNote1, selectedNote2] = state.selectedNotes
      // const { type: type2 } = selectedNote2
      // if (type2 !== 'note') {
      //   $message.info('选中的并不是音符，请重新选择')
      //   $store.commit('clearSelectedNotes')
      //   $store.commit('changeStateMode', StateMode.None);
      //   ($abcJsHelper as AbcJsHelper).chearSelectHight()
      //   return
      // }
      const abcString = state.abcString
      const tuneInfo = state.tuneInfo
      const abcStringHelper = new AbcStringHelper(tuneInfo)
      const result = abcStringHelper.changeTiles(selectedNote1, selectedNote2, abcString)
      const { newAbcStr, startChar: newStartChar, endChar: newEndChar } = result
      $store.commit('changeAbcString', newAbcStr)
      $store.commit('clearSelectedNotes')
      // $store.commit('clearSpecificSelectedNotes', selectedNote1)
      // $store.commit('clearSurplusSelectedNotes');
      $store.commit('changeStateMode', StateMode.None)
      if (newStartChar !== 0 && newEndChar !== 0) {
        $store.commit('changeSelectedNotes', {
          startChar: newStartChar,
          endChar: newEndChar
        })
      }
    }
  }
}