/*
 * @description: TuneBookMode 单谱表 还是双谱表
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-03 14:22:57
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-03 14:24:16
 */

export enum TuneBookMode {
  Single,
  Double
}