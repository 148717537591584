/*
 * @description: selection 选中音符的的回调
 * @version: 1.0
 * @autor: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-06-09 09:40:09
 * @lastEditors: xuhuale
 * @lastEditTime: 2021-06-25 15:43:00
 */
import _ from 'lodash'
import { StateMode } from '@/enum/state-mode'
import { State } from '@vue/runtime-core'
import store from '../../store'
import Command from '@/command/command'
const selection = (selectables: any[], selectedAbcelem: any, tuneNumber: any, classes: any, analysis: any, drag: any, mouseEvent: any) => {
  const state = store.state as unknown as State
  const stateMode = state.stateMode
  const selected = _.find(selectables, (item) => {
    return item.startChar === selectedAbcelem.startChar && item.endChar === selectedAbcelem.endChar
  })
  Object.assign(selected, {
    baseInfo: selectedAbcelem
  })
  if (stateMode === StateMode.Ties) {
    // 处理连音模式
    // store.commit('clearSurplusSelectedNotes') //清除到剩下一个
    store.commit('changeSelectedNotes', selected)
    const $command = window.$app.config.globalProperties.$command as Command
    $command.execAction('TiesSetting', {})
    return
  } else {
    store.commit('clearSelectedNotes')
  }
  store.commit('changeSelectedNotes', selected)
  // 拖拽改变音阶，因为abcjs本身拖拽的位置计算有问题，导致不好拖拽，暂时关闭
  if (selected && drag && selected.abcelem.pitches && drag && drag.step) {
    const $command = window.$app.config.globalProperties.$command as Command
    $command.execAction('NotePitchSetting', { value: drag.step })
  }
}

export default selection